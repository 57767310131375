// App.tsx
import ChatBotButton from "./components/Chat components/ChatBotButton";
import ChatBot from "./components/Chat components/ChatBot";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import LogIn from "./components/LogIn";
import CalendlyForCompanies from "./components/Calendly";
import CalendlyForMovers from "./components/CalendlyForMovers";
import Moving from "./components/Moving components/Moving";
import Commuting from "./components/Moving components/Commuting";
import LanguageSelector from "./components/LanguageSelector";
import Home from "./components/Home";
import { type } from "os";
import Registration from "./components/Registration";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Impressum from "./components/Impressum";
import MovingDetails from "./components/Moving components/MovingDetails";
import { ProtectedPageMove, ProtectedPageCommute } from "./components/ProtectedPage";
import CookieHandler from "./components/CookieHandler";
import LandingPage from "./components/Companies Landing Page components/LandingPage";
import MainPage from "./components/MainPage";
import ReactGA from "react-ga";
import CookiesSettings from "./components/CookiesSettings";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./components/ScrollToTop";
import SuccessfulValidation from "./components/SuccesfulValidation";

const TRACKING_ID = "G-CC6QRK8MX8";
ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const [showNavFooter, setShowNavFooter] = useState(true);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => setIsChatOpen(!isChatOpen);
  const isPasswordValid = localStorage.getItem("isPassword") === "true";

  return (
    <>
      <ScrollToTop />
      {showNavFooter && <NavBar />}

      <Routes>
        <Route
          path="/protected-page-moving"
          element={
            <ProtectedPageMove
              onProtectedPage={() => setShowNavFooter(false)}
              notOnProtectedPage={() => setShowNavFooter(true)}
            />
          }
        />
        <Route
          path="/protected-page-commuting"
          element={
            <ProtectedPageCommute
              onProtectedPage={() => setShowNavFooter(false)}
              notOnProtectedPage={() => setShowNavFooter(true)}
            />
          }
        />
        <Route path="/" element={<MainPage />} />
        <Route path="/companies-landing-page" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact-us" element={<CalendlyForCompanies />} />
        <Route path="/contact-us-movers" element={<CalendlyForMovers />} />
        {/*<Route path="/login" element={<LogIn />} />
        <Route path="/registration" element={<Registration />} />*/}
        {isPasswordValid && <Route path="/moving" element={<Moving />} />}
        {isPasswordValid && (
          <Route path="/moving-details" element={<MovingDetails />} />
          )}
          {isPasswordValid && (
            <Route path="/commuting" element={<Commuting />} />
          )}
      
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookies-settings" element={<CookiesSettings />} />
        <Route path="/impressum" element={<Impressum />} />
        {/*<Route path="/success-validation" element={<SuccessfulValidation />} />*/}

      </Routes>
      <CookieHandler
        privacyPolicyLink="/privacy-policy" 
        cookieSettingsLink="/cookies-settings" 
      />
      {/*{showNavFooter && !isChatOpen && <ChatBotButton onClick={toggleChat} />}
      {isChatOpen && <ChatBot onClose={toggleChat} />}*/}
      {showNavFooter && <Footer />}
    </>
  );
};

export default App;


