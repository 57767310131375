// LandingPage.tsx
import React from "react";
import LandingSection from "./LandingSection";
import InstructionsContainer from "./IntructionsContainer";
import ServiceOffer from "./ServiceOffer";
import ContactForm from "./ContactForm";
import DemoWeb from "./DemoWeb";
import styles from "../../styles/Landing Page styles/LandingPage.module.css"; // Asegúrate de que la ruta sea correcta
import BeforeAndAfter from "./BeforeAndAfter";
import OpeningCompanies from "./OpeningCompanies";
import Calendly from "../Calendly";
import PersonalInteraction from "./PersonalInteraction";
import InstructionTypes from "./InstructionTypes";

const LandingPage: React.FC = () => {
  return (
    <div>
      <OpeningCompanies />
      <LandingSection />
      <InstructionTypes />
      <PersonalInteraction />
      <InstructionsContainer />
      <BeforeAndAfter />
      {/*<ServiceOffer />*/}
      <div id="contact-us-section">
        <Calendly />
      </div>
      <ContactForm />
    </div>
  );
};

export default LandingPage;

/*https://finli.one/*/
