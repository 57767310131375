import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/Moving styles/Moving.module.css"; // Asegúrate de que la ruta sea correcta
import movingImage from "../../assets/moveAbroad.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faArrowRight,
  faSave,
  faRightLeft,
} from "@fortawesome/free-solid-svg-icons";
import MovingSelectionForm from "./MovingSelectionForm";
import { useTranslation } from "react-i18next";

const Moving: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const [type, setType] = useState(localStorage.getItem("type") || "");

  const [from, setFrom] = useState(localStorage.getItem("from") || "");
  const [to, setTo] = useState(localStorage.getItem("to") || "");

  const [approxDate, setApproxDate] = useState("");
  const [unknownDates, setUnknownDates] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [movingDetails, setMovingDetails] = useState({
    partner: false,
    pets: false,
    kids: false,
    euCitizen: false,
    car: false,
    general: true,
  });

  const countriesOrigin = [
    { id: "de", name: "moving.from-germany" },
    { id: "au", name: "moving.from-austria" },
    { id: "EU", name: "moving.from-EU" },
    { id: "NonEU", name: "moving.from-out-of-EU" },
  ];

  const countriesDestination = [
    { id: "ch", name: "moving.to-switzerland" },
    //{ id: "us", name: "United States" },
  ];
  const handleValidation = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    if (!from) newErrors.from = "commuting.error-from";
    if (!to) newErrors.to = "commuting.error-to";
    if (!approxDate && !unknownDates)
      newErrors.approxDate = "commuting.error-date";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    localStorage.setItem("from", from);
    localStorage.setItem("to", to);
  }, [from, to]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (handleValidation()) {
      const type = "moving";

      localStorage.setItem("selectedUserInfo", JSON.stringify(movingDetails));
      localStorage.setItem("type", type);
      localStorage.setItem("from", from);
      localStorage.setItem("to", to);
      localStorage.setItem("lastPath", location.pathname);
      navigate("/moving-details");
    }
  };

  const handleDetailChange = (detail: string, value: boolean) => {
    setMovingDetails((prevDetails) => ({
      ...prevDetails,
      [detail]: value,
    }));
  };

  return (
    <div className={styles.movingFormPageContainer}>
      <div className={styles.movingFormContainer}>
        <h1>{t("moving.title")}</h1>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown}>
            <label htmlFor="from">{t("moving.from-subtitle")}</label>
            <select
              id="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className={styles.selected}
            >
              <option value="">{t("moving.from-selection")}</option>
              {countriesOrigin.map((country) => (
                <option key={country.id} value={country.id}>
                  {t(country.name)}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.dropdown}>
            <label htmlFor="to">{t("moving.to-subtitle")}</label>
            <select
              id="to"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className={styles.selected}
            >
              <option value="">{t("moving.to-selection")}</option>
              {countriesDestination.map((country) => (
                <option key={country.id} value={country.id}>
                  {t(country.name)}
                </option>
              ))}
            </select>
          </div>
        </div>
        {errors.from && (
          <div className={styles.errorContainer}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={styles.errorIcon}
            />
            <p className={styles.error}>{t(errors.from)}</p>
          </div>
        )}
        {errors.to && (
          <div className={styles.errorContainer}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={styles.errorIcon}
            />
            <p className={styles.error}>{t(errors.to)}</p>
          </div>
        )}
        <div className={styles.dateContainer}>
          <label htmlFor="approxDate">{t("moving.date-subtitle")}</label>
          <input
            type="date"
            id="approxDate"
            value={approxDate}
            onChange={(e) => setApproxDate(e.target.value)}
          />
        </div>
        <div className="checkboxContainer">
          <label className="checkboxLabel">
            <input
              type="checkbox"
              checked={unknownDates}
              onChange={(e) => setUnknownDates(e.target.checked)}
            />
            {t("moving.date-not-clear-selection")}{" "}
          </label>
        </div>

        <div className={styles.informationContainer}>
          {errors.approxDate && (
            <div className={styles.errorContainer}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className={styles.errorIcon}
              />
              <p className={styles.error}>{t(errors.approxDate)}</p>
            </div>
          )}
          <MovingSelectionForm onDetailChange={handleDetailChange} />
          <div className={styles.buttonsContainer}>
            <button onClick={handleSubmit} className={styles.nextStepButton}>
              <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />{" "}
              {t("moving.next-step-button")}{" "}
            </button>

            <button
              onClick={() => navigate("/commuting")}
              className={styles.buttonGoBack}
            >
              <FontAwesomeIcon
                icon={faRightLeft}
                className={styles.icon}
              />{" "}
              {t("moving.commuting-not-moving-button")}{" "}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.movingImageContainer}>
        <img src={movingImage} alt="Login" className={styles.loginImage} />
      </div>
    </div>
  );
};

export default Moving;
