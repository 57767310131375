import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT DOCUMENTS READY
export const ImportantDocumentsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>
        <p>{t("tasks.group2.important-documents-paragraph1")}</p>

        <ul>
          <li>{t("tasks.group2.important-documents-list1-item1")}</li>
          <li>{t("tasks.group2.important-documents-list1-item2")}</li>
          <li>{t("tasks.group2.important-documents-list1-item3")}</li>
          <li>{t("tasks.group2.important-documents-list1-item4")}</li>
          <li>{t("tasks.group2.important-documents-list1-item5")}</li>
        </ul>
      </p>
    </div>
  );
};

// TASK PURCHASE VIGNETTE
export const PurchaseVignetteDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group2.vignette-paragraph1")}</p>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://via.admin.ch/shop/dashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group2.vignette-list1-item1")}{" "}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK CUSTOMS OFFICE OPENING HOURS

export const CustomsOfficeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group2.customs-office-paragraph1")}</p>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.offices.customs.admin.ch/?lang=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group2.customs-office-list1-item1")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK DOCUMENTS FOR IMPORTING YOUR CAR

export const DocumentsCarDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group2.documents-car-paragraph1")}</p>

      <p>
        <strong>{t("tasks.group2.documents-car-paragraph2-bold")}</strong>
      </p>

      <ul>
        <li>{t("tasks.group2.documents-car-list1-item1")}</li>
        <li>{t("tasks.group2.documents-car-list1-item2")}</li>
        <li>{t("tasks.group2.documents-car-list1-item3")}</li>
      </ul>

      {/*<ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.trabantclub.ch/PDF/anhang_vii_pruefungsformular1320astand01012001.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group2.documents-car-list2-item1")}
          </a>
        </li>
  </ul>*/}
    </div>
  );
};
