import React, { ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import LanguageSelector from "./LanguageSelector";
import styles from "../styles/NavBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NavBar: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={styles.nav}>
      <Link to="/">
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>
      <FontAwesomeIcon
        icon={isMenuOpen ? faTimes : faBars}
        className={styles.hamburger}
        onClick={toggleMenu}
      />
      <div className={isMenuOpen ? styles.navMenuActive : styles.navMenu}>
        {/*<ul className={styles.navList}>
          <CustomLink to="/moving" activeClassName={styles.active}>
            Moving
          </CustomLink>
          <CustomLink to="/commuting" activeClassName={styles.active}>
            Commuting
  </CustomLink>
        </ul>*/}
       
        <LanguageSelector />
        <Link to="/contact-us-movers">
          <button className={styles.navBarButton}>
            {t("navbar.contact-us-button")}
          </button>
        </Link>
        {!isLandingPage && (
          <>
          {/* to be unhidden as soon as we have a backend*/}
            {/*<Link to="/registration">
              <button className={styles.navBarButton}>
                {t("navbar.register-button")}
              </button>
            </Link>
            <Link to="/login">
              <button className={styles.navBarButton}>
                {t("navbar.login-button")}
              </button>
        </Link>*/}
          </>
        )}
      </div>
    </nav>
  );
};

export default NavBar;

interface CustomLinkProps {
  to: string;
  children: ReactNode;
  activeClassName: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  children,
  activeClassName,
}) => {
  const { pathname } = useLocation();

  const isActive = pathname === to;

  return (
    <li className={isActive ? activeClassName : ""}>
      <Link to={to}>{children}</Link>
    </li>
  );
};
