import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT REGISTER SWISS MUNICIPALITY
export const RegisterSwissDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.group3.register-swiss-paragraph1")}</p>
    </div>
  );
};

// TASK RESIDENCE PERMIT
export const ResidencePermitDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.residence-permit-paragraph1")}</p>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.sem.admin.ch/sem/de/home/sem/kontakt/kantonale_behoerden/adressen_kantone_und.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.residence-permit-list1-item1")}
          </a>
        </li>
      </ul>
      <p>
        <strong>{t("tasks.group3.residence-permit-list2-bold")} </strong>{" "}
      </p>
      <ul>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist2-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.group3.residence-permit-sublist2-item1")}</li>
            <li>{t("tasks.group3.residence-permit-sublist2-item1")} </li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist3-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.group3.residence-permit-sublist3-item1")}</li>
            <li>{t("tasks.group3.residence-permit-sublist3-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist4-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.group3.residence-permit-sublist4-item1")}</li>
            <li>{t("tasks.group3.residence-permit-sublist4-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist5-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.group3.residence-permit-sublist5-item1")}</li>
            <li>{t("tasks.group3.residence-permit-sublist5-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist6-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.group3.residence-permit-sublist6-item1")}</li>
            <li>{t("tasks.group3.residence-permit-sublist6-item2")}</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

// TASK BANK ACCOUNT

export const OpenBankAccountDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.open-bank-paragraph1")}</p>
    </div>
  );
};

// TASK CURRENCY EXCHANGE

export const CurrencyExchangeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.currency-exchange-paragraph1")}</p>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.revolut.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.currency-exchange-list1-item1")}
          </a>
        </li>
        <li>
          <a
            href="https://wise.com/ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.currency-exchange-list1-item2")}
          </a>
        </li>
        <li>
          <a
            href="https://www.neon-free.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.currency-exchange-list1-item3")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK PHONE

export const PhoneAndInternetDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group3.phone-internet-paragraph1")}
        {t("tasks.group3.phone-internet-paragraph2")}
        <a
          href={t("tasks.group3.phone-internet-link-alao")}
          target="_blank"
          rel="noopener noreferrer"
        >alao.com</a>
        {t("tasks.group3.phone-internet-paragraph3")}
        
      </p>
    </div>
  );
};

// TASK REGISTER PETS

export const RegisterPetsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.register-pets-paragraph1")}</p>
      <ul>
        <li>{t("tasks.group3.register-pets-list1-item1")} </li>
        <li>{t("tasks.group3.register-pets-list1-item2")}</li>
        <li>{t("tasks.group3.register-pets-list1-item3")} </li>
      </ul>
      <ul>
        <a
          href="https://www.amicus.ch/en"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("tasks.group3.register-pets-list1-item4")}{" "}
        </a>
      </ul>
    </div>
  );
};

export const HealthInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.health-insurance-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {t("tasks.group3.health-insurance-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.group3.health-insurance-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.group3.health-insurance-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.group3.health-insurance-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.group3.health-insurance-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.group3.health-insurance-list1-item3")}
        </li>
        <li>
          <strong>
            {t("tasks.group3.health-insurance-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.group3.health-insurance-list1-item4")}
        </li>
      </ul>
    </div>
  );
};

export const GetSwissInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.swiss-insurance-paragraph1")}</p>
    </div>
  );
};

export const ChangeDriverLicenseDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.change-driver-license-paragraph1")}</p>

      <p>
        <strong>
          {t("tasks.group3.change-driver-license-paragraph2-bold")}
        </strong>
      </p>

      <ul>
        <li>
          {t("tasks.group3.change-driver-license-list1-item1")}{" "}
          <a
            href="https://www.zh.ch/de/mobilitaet/fuehrerausweis-fahren-lernen/auslaendischer-fuehrerausweis/auslaendischen-fuehrerausweis-umtauschen.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group3.change-driver-license-list1-item1-link")}{" "}
          </a>
        </li>
        <li>{t("tasks.group3.change-driver-license-list1-item2")}</li>
      </ul>
    </div>
  );
};

export const EBankingTwintDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        <ul>
          <li>
            {" "}
            <strong>
              {" "}
              {t("tasks.group3.ebanking-twint-list1-item1-bold")}
            </strong>
            {t("tasks.group3.ebanking-twint-list1-item1")}
          </li>

          <li>
            {" "}
            <strong>
              {" "}
              {t("tasks.group3.ebanking-twint-list1-item2-bold")}
            </strong>
            {t("tasks.group3.ebanking-twint-list1-item2")}
          </li>
        </ul>

        <ul>
          <a
            href="https://www.twint.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.ebanking-twint-list1-item3")}
          </a>
        </ul>
      </p>
    </div>
  );
};

export const InformBanksOldCountryDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.inform-banks-old-country-paragraph1")}</p>
    </div>
  );
};

export const MobilityDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.mobility-paragraph1")}</p>

      <p>
        <strong>{t("tasks.group3.mobility-paragraph2-bold")} </strong>{" "}
      </p>
      <ul>
        <li>
          <strong> {t("tasks.group3.mobility-list1-item1-bold")} </strong>
          {t("tasks.group3.mobility-list1-item1-1")}
          <br />
          <a
            href="https://www.sbb.ch/de/billette-angebote/abos/halbtax.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.mobility-list1-subitem1")}
          </a>
          <br />
          <a
            href="https://www.swisspass.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.mobility-list1-subitem2")}
          </a>
        </li>

        <li>
          <strong> {t("tasks.group3.mobility-list1-item2-bold")} </strong> {t("tasks.group3.mobility-list1-item2")}
          <br />
          <a
            href="https://www.swisspass.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.mobility-list1-subitem2")}
          </a>
        </li>

        <li>
          <strong> {t("tasks.group3.mobility-list1-item3-bold")} </strong>
          {t("tasks.group3.mobility-list1-item3")}
          <br />
          <a
            href="https://www.mobility.ch/de/privatkunden"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.mobility-list1-subitem3")}
          </a>
        </li>

        <li>
          <strong> {t("tasks.group3.mobility-list1-item4-bold")} </strong>
          {t("tasks.group3.mobility-list1-item4")}
        </li>

        <li>
          <strong> {t("tasks.group3.mobility-list1-item5-bold")} </strong>
          {t("tasks.group3.mobility-list1-item5")}
          <br />
          <a
            href="https://www.publibike.ch/de/home?gclid=CjwKCAjw3dCnBhBCEiwAVvLcu77FFzg7FO912qp_m7do5XTvElZae6KMEXWU4NOArjc3fnxrFedQVRoCeDwQAvD_BwE"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.group3.mobility-list1-subitem5")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const SupermarketsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.supermarkets-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {" "}
            {t("tasks.group3.supermarkets-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.group3.supermarkets-list1-item1")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.group3.supermarkets-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.group3.supermarkets-list1-item2")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.group3.supermarkets-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.group3.supermarkets-list1-item3")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.group3.supermarkets-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.group3.supermarkets-list1-item4")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.group3.supermarkets-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.group3.supermarkets-list1-item5")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.group3.supermarkets-list1-item6-bold")}{" "}
          </strong>
          {t("tasks.group3.supermarkets-list1-item6")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.group3.supermarkets-list1-item7-bold")}{" "}
          </strong>
          {t("tasks.group3.supermarkets-list1-item7")}
        </li>
      </ul>
    </div>
  );
};

export const ChildcareDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.childcare-paragraph1")}</p>

      <p>
        <strong> {t("tasks.group3.childcare-paragraph1-bold")} </strong>
      </p>

      <p>{t("tasks.group3.childcare-paragraph2")}</p>

      <p>
        <strong> {t("tasks.group3.childcare-list1-bold")} </strong>
      </p>

      <ul style={{ listStyleType: "circle" }}>
        <li>
          <strong> {t("tasks.group3.childcare-list1-item1-bold")}</strong>{" "}
          {t("tasks.group3.childcare-list1-item1")}
        </li>
        <li>
          <strong> {t("tasks.group3.childcare-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.childcare-list1-item2")}
        </li>
        <li>
          <strong> {t("tasks.group3.childcare-list1-item3-bold")}</strong>{" "}
          {t("tasks.group3.childcare-list1-item3")}
        </li>
      </ul>

      <br />

      <p>
        <strong> {t("tasks.group3.childcare-list2-bold")} </strong>
      </p>

      <ul style={{ listStyleType: "circle" }}>
        <li>
          <strong>{t("tasks.group3.childcare-sublist2-item1-bold")}</strong>

          <ul style={{ listStyleType: "square" }}>
            <li>
              <strong>
                {t("tasks.group3.childcare-subsublist2-item1-bold")}{" "}
              </strong>{" "}
              {t("tasks.group3.childcare-subsublist2-item1")}
            </li>
            <li>
              <strong>
                {t("tasks.group3.childcare-subsublist2-item2-bold")}
              </strong>{" "}
              {t("tasks.group3.childcare-subsublist2-item2")}
              <br />
              <a
                href="https://www.ch.ch/de/arbeit/familie-und-arbeit/kinderbetreuung/#kindertagesstatten-kitas-krippen"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("tasks.group3.childcare-subsublist2-item3")}
              </a>
              <br />
              <a
                href="https://www.stadt-zuerich.ch/sd/de/index/familien_kinder_jugendliche/kinderbetreuung/stadteigene_kitas.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                {t("tasks.group3.childcare-subsublist2-item4")}
              </a>
              <br />
              <a
                href="https://www.maps.stadt-zuerich.ch/zueriplan3/stadtplan.aspx#route_visible=true&basemap=Basiskarte+(Geb%C3%A4udeschr%C3%A4gansicht)&map=&scale=128000&xkoord=2691486.2830257276&ykoord=1253025.0946467994&lang=&layer=Kita%3A%3A38&window=&selectedObject=kk027&selectedLayer=&toggleScreen=1&legacyUrlState=&drawings="
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                {t("tasks.group3.childcare-subsublist2-item5")}
              </a>
            </li>
          </ul>
        </li>

        <li>
          <strong>{t("tasks.group3.childcare-sublist2-item2-bold")}</strong>{" "}
          {t("tasks.group3.childcare-sublist2-item2")}
          <br />
          <a
            href="https://www.kibesuisse.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group3.childcare-sublist2-item3")}{" "}
          </a>
        </li>

        <li>
          <strong>{t("tasks.group3.childcare-sublist2-item4-bold")}</strong>{" "}
          {t("tasks.group3.childcare-sublist2-item4")}
        </li>

        <li>
          <strong>{t("tasks.group3.childcare-sublist2-item5-bold")}</strong>

          <ul style={{ listStyleType: "square" }}>
            <li>
              <strong>
                {t("tasks.group3.childcare-subsublist3-item5-bold")}
              </strong>{" "}
              {t("tasks.group3.childcare-subsublist3-item5")}
              <br />
              <a
                href="https://www.ch.ch/de/arbeit/familie-und-arbeit/kinderbetreuung/#kindertagesstatten-kitas-krippen"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                {t("tasks.group3.childcare-subsublist3-item5-link")}
              </a>
            </li>
            <strong>
              {t("tasks.group3.childcare-subsublist3-item6-bold")}{" "}
            </strong>{" "}
            {t("tasks.group3.childcare-subsublist3-item6")}
            <br />
            <a
              href="https://www.ch.ch/de/arbeit/familie-und-arbeit/kinderbetreuung/#kindertagesstatten-kitas-krippen"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group3.childcare-subsublist3-item6-link")}
            </a>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export const EducationSystemDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.education-system-paragraph1")}</p>
      <p>{t("tasks.group3.education-system-paragraph2")}</p>

      <ul>
        <a
          href="https://www.edk.ch/de/bildungssystem-ch"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          {t("tasks.group3.education-system-item1")}
        </a>
      </ul>
    </div>
  );
};

export const OnlineShoppingDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.online-shopping-paragraph1")}</p>
      <p>
        {t("tasks.group3.online-shopping-paragraph2-part1")}
        {" "}
        <a
          href="https://www.galaxus.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
         galaxus.ch
        </a>{" "}
        {t("tasks.group3.online-shopping-paragraph2-part2")}
        {" "}
        <a
          href="https://www.digitec.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          digitec.ch
        </a>
        {t("tasks.group3.online-shopping-paragraph2-part3")}
       
            <br />
            <br />
            <strong> {t("tasks.group3.online-shopping-paragraph3")} </strong>
            {t("tasks.group3.online-shopping-paragraph4-part1")}
            {" "}
            <a
              href="https://www.tutti.ch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              tutti.ch
            </a>{" "}
            {t("tasks.group3.online-shopping-paragraph4-part2")}
            <a
              href="https://www.ricardo.ch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              ricardo.ch
            </a>
            {t("tasks.group3.online-shopping-paragraph4-part3")}
         
      </p>
    </div>
  );
};

export const SerafeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.serafe-paragraph1")}</p>
    </div>
  );
};

export const WasteDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group3.waste-paragraph1")}

        <ul>
          <li>
            <strong> {t("tasks.group3.waste-list1-item1-bold")} </strong>
            {t("tasks.group3.waste-list1-item1")}
          </li>

          <li>
            <strong> {t("tasks.group3.waste-list1-item2-bold")} </strong>
            {t("tasks.group3.waste-list1-item2-part1")}{" "}
            <a
              href="https://www.swisspass.ch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {t("tasks.group3.waste-list1-item2-part2")}{" "}
            </a>
            {t("tasks.group3.waste-list1-item2-part3")}
          </li>

          <li>
            <strong> {t("tasks.group3.waste-list1-item3-bold")} </strong>
            {t("tasks.group3.waste-list1-item3")}
          </li>

          <li>
            <strong>{t("tasks.group3.waste-list1-item4-bold")} </strong>
            {t("tasks.group3.waste-list1-item4")}
          </li>

          <li>
            <strong> {t("tasks.group3.waste-list1-item5-bold")} </strong>
            {t("tasks.group3.waste-list1-item5")}
          </li>

          <li>
            <strong> {t("tasks.group3.waste-list1-item6-bold")} </strong>
            {t("tasks.group3.waste-list1-item6")}
          </li>

          <li>
            <strong> {t("tasks.group3.waste-list1-item7-bold")} </strong>
            {t("tasks.group3.waste-list1-item7")}
          </li>
        </ul>
      </p>
    </div>
  );
};

export const RegisterCarDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.register-car-paragraph1")} </p>

      <p>
        {" "}
        <strong>{t("tasks.group3.register-car-paragraph2-bold")} </strong>{" "}
      </p>

      <ul>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item1-bold")} </strong>{" "}
          {t("tasks.group3.register-car-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.register-car-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item3-bold")} </strong>
        </li>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item4-bold")} </strong>
        </li>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item5-bold")}</strong>{" "}
          {t("tasks.group3.register-car-list1-item5")}
        </li>
      </ul>

      <a
        href="https://asa.ch/strassenverkehrsaemter/adressen/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        {t("tasks.group3.register-car-list1-item6")}
      </a>
    </div>
  );
};
