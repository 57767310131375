import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT DOCUMENTS READY
export const GeneralInformationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <ul>
        <li>
          {t("tasks.commuting.general-information-list1-item1-part1")}{" "}
          <strong>
            {t("tasks.commuting.general-information-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.commuting.general-information-list1-item1-part2")}{" "}
        </li>
        <li>
          {t("tasks.commuting.general-information-list1-item2-part1")}
          <strong>
            {t("tasks.commuting.general-information-list1-item2-bold")}
          </strong>
          {t("tasks.commuting.general-information-list1-item2-part2")}{" "}
        </li>
        <li>{t("tasks.commuting.general-information-list1-item3")}</li>
      </ul>
    </div>
  );
};

// TASK CERTIFICATE RESIDENCE
export const CertificateResidenceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <ul>
        <li>
          {t("tasks.commuting.certificate-residence-list1-item1-part1")}{" "}
          <a
            href="https://www.formulare-bfinv.de/ffw/form/display.do?%24context=5927A66C179B6BDB71B3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.commuting.certificate-residence-list1-item1-link")}{" "}
          </a>
          {t("tasks.commuting.certificate-residence-list1-item1-part2")}{" "}
        </li>
        <li>{t("tasks.commuting.certificate-residence-list1-item2")}</li>
      </ul>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.formulare-bfinv.de/ffw/form/display.do?%24context=5927A66C179B6BDB71B3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.commuting.certificate-residence-list1-item3")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK WORK PERMIT
export const WorkPermitDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.work-permit-paragraph1")}</p>

      <p>
        <strong>{t("tasks.commuting.work-permit-paragraph2-bold")} </strong>{" "}
        {t("tasks.commuting.work-permit-paragraph2")}
      </p>
      <p>
        <strong>{t("tasks.commuting.work-permit-list2-bold")} </strong>{" "}
      </p>
      <ul>
        <li>
          <strong>{t("tasks.commuting.work-permit-sublist2-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.commuting.work-permit-sublist2-item1")}</li>
            <li>{t("tasks.commuting.work-permit-sublist2-item2")} </li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.commuting.work-permit-sublist3-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.commuting.work-permit-sublist3-item1")}</li>
            <li>{t("tasks.commuting.work-permit-sublist3-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.commuting.work-permit-sublist4-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.commuting.work-permit-sublist4-item1")}</li>
            <li>{t("tasks.commuting.work-permit-sublist4-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.commuting.work-permit-sublist5-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.commuting.work-permit-sublist5-item1")}</li>
            <li>{t("tasks.commuting.work-permit-sublist5-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>{t("tasks.commuting.work-permit-sublist6-bold")}</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.commuting.work-permit-sublist6-item1")}</li>
            <li>{t("tasks.commuting.work-permit-sublist6-item2")}</li>
          </ul>
        </li>
      </ul>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.sem.admin.ch/sem/de/home/sem/kontakt/kantonale_behoerden/adressen_kantone_und.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.commuting.work-permit-list1-link")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK BANK ACCOUNT

export const OpenBankAccountCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.open-bank-paragraph1")}</p>
    </div>
  );
};

// TASK PHONE ACCOUNT

export const MobilePhoneCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.commuting.mobile-phone-paragraph1")}
        
      </p>
    </div>
  );
};



// TASK HEALTH INSURANCE

export const HealthInsuranceCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.health-insurance-paragraph1")}</p>
      <p>
        <strong>
          {t("tasks.commuting.health-insurance-paragraph2-bold")}{" "}
        </strong>
        {t("tasks.commuting.health-insurance-paragraph2")}
      </p>

      <ul>
        <li>
          <strong>
            {t("tasks.commuting.health-insurance-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.commuting.health-insurance-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.health-insurance-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.commuting.health-insurance-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.health-insurance-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.commuting.health-insurance-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK INSURANCES FOR COMMUTERS

export const InsurancesCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item3")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item4")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item5")}
        </li>
      </ul>
    </div>
  );
};

// TASK RETIREMENT FOR COMMUTERS

export const RetirementCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.retirement-paragraph1")}</p>
      <ul>
        <li>
          <strong>{t("tasks.commuting.retirement-list1-item1-bold")}</strong>
          {t("tasks.commuting.retirement-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.commuting.retirement-list1-item2-bold")}</strong>
          {t("tasks.commuting.retirement-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.commuting.retirement-list1-item3-bold")}</strong>
          {t("tasks.commuting.retirement-list1-item3")}
        </li>
      </ul>

      <p>
        {t("tasks.commuting.retirement-paragraph2-part1")}{" "}
        <strong>{t("tasks.commuting.retirement-paragraph2-bold")}</strong>
        {t("tasks.commuting.retirement-paragraph2-part2")}
      </p>
      <p>
        <strong>{t("tasks.commuting.retirement-paragraph3-bold")}</strong>{" "}
        {t("tasks.commuting.retirement-paragraph3")}
      </p>
    </div>
  );
};

// TASK TAXATION

export const TaxationCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.taxation-paragraph1")}</p>
      <p>
        <strong>{t("tasks.commuting.taxation-paragraph2-bold")} </strong>
        {t("tasks.commuting.taxation-paragraph2")}
      </p>
      <p>
        <strong>{t("tasks.commuting.taxation-paragraph3-bold")} </strong>
        {t("tasks.commuting.taxation-paragraph3-part1")}{" "}
        <a
          href="https://www.grenzgaengerdienst.de/brutto-netto-rechner-grenzgaenger-schweiz"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("tasks.commuting.taxation-paragraph3-link")}
        </a>{" "}
        {t("tasks.commuting.taxation-paragraph3-part2")}{" "}
      </p>
    </div>
  );
};
