// MovingDetails.tsx
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../../styles/Moving styles/MovingCommutingDetails.module.css";
import tasksImage from "../../assets/toDos.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faSquare,
  faToggleOff,
  faToggleOn,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../ProgressBar";
import { Task, groupNames, initialTasks } from "./TasksData";
import { useTranslation } from "react-i18next";

const MovingDetails: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const [tasks, setTasks] = useState<Task[]>(initialTasks);

  const [expandedGroup, setExpandedGroup] = useState<number | null>(null);
  const [expandedTask, setExpandedTask] = useState<number | null>(null);
  const [showCompletedGroups, setShowCompletedGroups] = useState(true);

  const resetLocalStorage = () => {
    localStorage.clear();
    setTasks(initialTasks);
  };

  useEffect(() => {
    const storedTasks = localStorage.getItem("tasks");

    if (storedTasks) {
      const parsedTasks = JSON.parse(storedTasks);
      setTasks(parsedTasks);
    }
  }, []);

  const toggleTaskCompleted = (id: string) => {
    const updatedTasks = tasks.map((task) =>
      task.id === id ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
    //localStorage.setItem("tasks", JSON.stringify(updatedTasks));
  };

  const toggleExpandGroup = (group: number) => {
    setExpandedGroup((prevGroup) => (prevGroup === group ? null : group));
    setExpandedTask(null);
  };

  const toggleExpandTask = (id: number) => {
    setExpandedTask((prevTask) => (prevTask === id ? null : id));
  };

  const isSelectedCategory = (category: string) => {
    const selectedUserInfo = JSON.parse(
      localStorage.getItem("selectedUserInfo") || "{}"
    );
    return selectedUserInfo[category] === true;
  };

  const lastPath = localStorage.getItem("type");
  console.log(lastPath);
  const isSelectedType = (type: string) => {
    // Comprueba si 'lastPath' es nulo para manejar ese caso correctamente
    if (!lastPath) {
      return false; // Devuelve false si no hay 'lastPath' definido, así que ninguna tarea se filtra por 'type'
    }
    // Compara el 'type' de la tarea con 'lastPath'
    return type === lastPath;
  };

  // Filtra las tareas que cumplen con la condición isSelectedCategory
  const selectedTasks = tasks.filter(
    (task) => isSelectedCategory(task.category) && isSelectedType(task.type)
  );

  // Ajustar isGroupEmpty para considerar solo las tareas seleccionadas
  const isGroupEmpty = (groupIndex: number) => {
    return (
      selectedTasks.filter((task) => task.group === groupIndex + 1).length === 0
    );
  };

  const isGroupCompleted = (group: number) => {
    const groupTasks = selectedTasks.filter(
      (selectedTasks) => selectedTasks.group === group
    );
    return (
      groupTasks.length > 0 &&
      groupTasks.every((selectedTasks) => selectedTasks.completed)
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={tasksImage} alt="Tasks" className={styles.tasksImage} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{t("moving-details.title")} </h1>
        <p className={styles.subtitle}>
          {t("moving-details.paragraph-title")}{" "}
        </p>

        <div className={styles.toggleButtonsContainer}>
          <button
            className={`${styles.toggleButton} ${
              showCompletedGroups ? styles.toggleOn : styles.toggleOff
            }`}
            onClick={() => setShowCompletedGroups((prev) => !prev)}
          >
            <FontAwesomeIcon
              icon={showCompletedGroups ? faToggleOn : faToggleOff}
              className={styles.toggleIcon}
            />
            {showCompletedGroups
              ? t("moving-details.toggle-hide-groups")
              : t("moving-details.toggle-show-groups")}
          </button>
        </div>

        <ProgressBar
          totalTasks={selectedTasks.length}
          completedTasks={tasks.filter((task) => task.completed).length}
        />

        {/* Nuevo componente ProgressBar */}
        {/* Nuevo componente ProgressBar */}
        {/* Nuevo componente ProgressBar */}
        {/* Nuevo componente ProgressBar */}

        <div className={styles.tasksList}>
          {groupNames.map((groupName, groupIndex) => {
            const isCurrentGroupCompleted = isGroupCompleted(groupIndex + 1);
            if (!showCompletedGroups && isCurrentGroupCompleted) {
              return null;
            }
            // Verificar si el grupo está vacío
            if (isGroupEmpty(groupIndex)) {
              return null;
            }

            return (
              <div key={groupIndex} className={styles.groupContainer}>
                <div
                  className={styles.groupHeader}
                  onClick={() => toggleExpandGroup(groupIndex + 1)}
                >
                  <span>{t(groupName)}</span>
                  <FontAwesomeIcon
                    icon={
                      isGroupCompleted(groupIndex + 1)
                        ? faCheckSquare
                        : faSquare
                    }
                    className={`${styles.groupIcon} ${
                      isGroupCompleted(groupIndex + 1) && styles.checked
                    }`}
                  />
                </div>
                {expandedGroup === groupIndex + 1 && (
                  <div className={styles.groupTasks}>
                    {tasks
                      .filter((task) => task.group === groupIndex + 1)

                      .map((task) => {
                        const isVisibleGroup = isSelectedCategory(
                          groupName.toLowerCase()
                        ); //not needed
                        const isVisibleCategory = isSelectedCategory(
                          task.category
                        );
                        const isVisibleType = isSelectedType(task.type);

                        const isVisibleTask =
                          isVisibleCategory && isVisibleType;

                        return (
                          isVisibleTask && (
                            <div key={task.id} className={styles.taskItem}>
                              <div
                                className={styles.taskHeader}
                                onClick={() =>
                                  toggleExpandTask(Number(task.id))
                                }
                              >
                                <span className={styles.taskTitle}>
                                  {t(task.title)}
                                </span>
                                <input
                                  type="checkbox"
                                  checked={task.completed}
                                  // When calling toggleTaskCompleted, convert id to string
                                  onChange={() =>
                                    toggleTaskCompleted(task.id.toString())
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  className={styles.checkbox}
                                />
                              </div>
                              {expandedTask === Number(task.id) && (
                                <div className={styles.taskDescription}>
                                  {task.description}
                                </div>
                              )}
                            </div>
                          )
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/*<button onClick={resetLocalStorage} className={styles.resetButton}>
          Reset Local Storage
        </button>*/}
        <div className={styles.containerDetailsButton}>
          <Link to="/contact-us-movers">
            <button className={styles.actionButton}>
              <FontAwesomeIcon icon={faPhone} className={styles.iconPhone} />
              {t("moving-details.call-button")}{" "}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MovingDetails;
