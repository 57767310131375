// InstructionsContainer.tsx
import React, { useState, useEffect } from "react";
import styles from "../../styles/Landing Page styles/InstructionsContainer.module.css";
import instructionImg1 from "../../assets/instructionsImg1.png";
import instructionImg2 from "../../assets/instructionsImg2.png";
import instructionImg3 from "../../assets/instructionsImg3.png";
import instructionImg1DE from "../../assets/instructionsImg1-de.png";
import instructionImg2DE from "../../assets/instructionsImg2-de.png";
import instructionImg3DE from "../../assets/instructionsImg3-de.png";
import { useTranslation } from "react-i18next";

const InstructionsContainer: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const videoLink =
    i18n.language === "de" ? "AXf-9MdChho" : "k6EpJCmBda4?si=pexevjruLmZnjk5b";

  const [showVideo, setShowVideo] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1150);

  const handleWatchDemoClick = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1150);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.instructionsContainer}>
      <div className={styles.instructionsContent}>
        <h2>{t("companies-landing-page.instructions-title-blue")}</h2>
        <div className={styles.instructionImage}>
          {isSmallScreen ? (
            <>
              <img
                className={styles.instructionImage}
                src={
                  i18n.language === "de" ? instructionImg2DE : instructionImg2
                }
                alt="Instruction Image 2"
              />
              <img
                className={styles.instructionImage}
                src={
                  i18n.language === "de" ? instructionImg3DE : instructionImg3
                }
                alt="Instruction Image 3"
              />
            </>
          ) : (
            <img
              className={styles.instructionImage}
              src={i18n.language === "de" ? instructionImg1DE : instructionImg1}
              alt="Instruction Image 1"
            />
          )}
        </div>
      </div>
      {/*<button className={styles.watchDemoButton} onClick={handleWatchDemoClick}>
        {t("companies-landing-page.instructions-paragraph")}
      </button>

      {showVideo && (
        <div className={styles.videoOverlay} onClick={handleCloseVideo}>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoLink}`}
            title="Demo Video"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}
      */}
    </div>
  );
};

export default InstructionsContainer;
