import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeEurope,
  faSuitcaseRolling,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Home styles/NavigationButtons.module.css";
import { useTranslation } from "react-i18next";

const NavigationButtons: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  const handleMoveClick = () => {
    // Verificar el valor en el localStorage
    const isPasswordValid = localStorage.getItem("isPassword") === "true";
    // Redirigir solo si la contraseña es válida
    if (isPasswordValid) {
      navigate("/moving");
    } else {
      navigate("/protected-page-moving");
    }
  };

  const handleCommuteClick = () => {
    // Verificar el valor en el localStorage
    const isPasswordValid = localStorage.getItem("isPassword") === "true";
    // Redirigir solo si la contraseña es válida
    if (isPasswordValid) {
      navigate("/commuting");
    } else {
      navigate("/protected-page-commuting");
    }
  };

  return (
    <div className={styles.navigationButtonsContainer}>
      <p>{t("home.navigation-title")}</p>

      <div className={styles.buttonsContainer}>
        <button
          className={styles.navigationButton}
          onClick={handleMoveClick}
        >
          {t("home.navigation-move-button")}
        </button>

        <button
          className={styles.navigationButton}
          onClick={handleCommuteClick}
        >
          {t("home.navigation-commute-button")}
          <span className={styles.tooltip}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className={styles.tooltipIcon}
            />
            <span className={styles.tooltipText}>
              {t("home.navigation-commute-tooltip")}
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default NavigationButtons;
