import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaw,
  faBaby,
  faCar,
  faUser,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Moving styles/MovingSelectionForm.module.css";
import { useTranslation } from "react-i18next";


interface MovingSelectionFormProps {
  onDetailChange: (detail: string, value: boolean) => void;
}

const MovingSelectionForm: React.FC<MovingSelectionFormProps> = ({
  onDetailChange,
}) => {
  // Opciones de selección

  const [t, i18n] = useTranslation("global");

  const selections = [
    { id: "partner", label: t("moving-form.selection1"), icon: faHeart },
    { id: "pets", label: t("moving-form.selection2"), icon: faPaw },
    { id: "kids", label: t("moving-form.selection3"), icon: faBaby },
    { id: "euCitizen", label: t("moving-form.selection4"), icon: faUser },
    { id: "car", label: t("moving-form.selection5"), icon: faCar },
  ];

  const handleChange = (id: string) => {
    onDetailChange(id, true); 
  };

  return (
      <div className={styles.form}>
        <h2> {t("moving-form.title")}</h2>
        <div className={styles.optionsContainer}>

        
      {selections.map((selection) => (
        <div key={selection.id} className={styles.option}>
          <input
            type="checkbox"
            id={selection.id}
            onChange={() => handleChange(selection.id)}
          />
          <label htmlFor={selection.id}>
            <FontAwesomeIcon icon={selection.icon} className={styles.icon} />
            {selection.label}
          </label>{" "}
        </div>
      ))}
    </div>
    </div>
  );
};

export default MovingSelectionForm;
