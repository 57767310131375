// Home.tsx
import React from "react";
import HomeTextImage from "./Home components/HomeTextImage";
import HomeVideoText from "./Home components/HomeVideoText";
import FAQ from "./Home components/FAQ";

const Home = () => {
  return (
    <div>
      <HomeTextImage />
      <HomeVideoText />
      <FAQ/>
    </div>
  );
};

export default Home;

