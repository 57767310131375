import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT DOCUMENTS READY
export const RetirementDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.group4.retirement-paragraph1")}</p>
      <p>
        <strong>{t("tasks.group4.retirement-paragraph2-bold")}</strong> {" "}
      </p>

      <ul>
        <p>{t("tasks.group4.retirement-paragraph3")}</p>

        <li>
          <strong>{t("tasks.group4.retirement-list1-item1-bold")}</strong>{" "}
          {t("tasks.group4.retirement-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.group4.retirement-list1-item2-bold")}</strong>{" "}
          {t("tasks.group4.retirement-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.group4.retirement-list1-item3-bold")}</strong>{" "}
          {t("tasks.group4.retirement-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK PURCHASE VIGNETTE
export const TaxDeclarationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group4.tax-declaration-paragraph1")}</p>
    </div>
  );
};
