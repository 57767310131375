// OpeningCompanies.tsx
import React from "react";
import personalImg from "../../assets/personalInteraction.png";
import styles from "../../styles/Landing Page styles/OpeningCompanies.module.css"; // Asegúrate de tener tu archivo de estilos
import { useTranslation } from "react-i18next";

const PersonalInteraction: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.openingCompanies}>
      <div className={styles.leftSection}>
        <div className={styles.textContent}>
          <h1>
            {t("companies-landing-page.personal-title-blue")}{" "}
            <span>{t("companies-landing-page.personal-title-black")}</span>
          </h1>
          <p>
            {t("companies-landing-page.personal-paragraph-normal1")}{" "}
            <span>{t("companies-landing-page.personal-paragraph-bold1")}</span>{" "}
            {t("companies-landing-page.personal-paragraph-normal2")}{" "}
            <span> {t("companies-landing-page.personal-paragraph-bold2")}</span>{" "}
            {t("companies-landing-page.personal-paragraph-normal3")}{" "}
        <br />
        <br />
            {t("companies-landing-page.personal-paragraph-normal4")}{" "}
            <span> {t("companies-landing-page.personal-paragraph-bold3")}</span>{" "}
            {t("companies-landing-page.personal-paragraph-normal5")}
          </p>
        </div>
      </div>
      <div className={styles.rightSection}>
        <img src={personalImg} alt="Descripción de la imagen" />
      </div>
    </div>
  );
};

export default PersonalInteraction;
