import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faCheck } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/ContactUs.module.css"; // Asegúrate de importar tu archivo de estilos
import { useTranslation } from "react-i18next";

interface FormData {
  name: string;
  surname: string;
  email: string;
  company: string;
  message: string;
  phoneNumber: string;
}

const ContactForm: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const initialFormData: FormData = {
    name: "",
    surname: "",
    email: "",
    company: "",
    message: "",
    phoneNumber: "",
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Agrega lógica para validar y enviar el formulario según tus necesidades
    console.log("Form submitted:", formData);
  };

  return (
    <div className={styles.contactUsLayout}>
      <div className={styles.contactText}>
        <h2> {t("companies-landing-page.contact-section-pretitle")} </h2>
        <h1>
            {t("companies-landing-page.contact-section-title-blue")}{" "}
          {" "}
          <span className={styles.highlighted}>
          {t("companies-landing-page.contact-section-title-black")}
          </span>
        </h1>
        <p>
          {t("companies-landing-page.contact-section-paragraph1-normal1")}
          {" "}
          <span>
            {t("companies-landing-page.contact-section-paragraph1-bold")}
            {" "}
          </span>
          {t("companies-landing-page.contact-section-paragraph1-normal2")}
          <br /> <br />
          {t("companies-landing-page.contact-section-paragraph2-normal1")}
          <span>
          {" "}{t("companies-landing-page.contact-section-paragraph2-bold")}{" "}
          </span>
          {t("companies-landing-page.contact-section-paragraph2-normal2")}
          <br /> <br />
          {t("companies-landing-page.contact-section-paragraph3-normal")}{" "}
          <a href="mailto:info@finli.one"> {t("companies-landing-page.email-link")}</a>
        </p>
      </div>
      {/*<div className={styles.contactFormContainer}>
        <form onSubmit={handleSubmit} className={styles.contactUsContainer}>
          <div className={styles.formGroup}>
            <input
              placeholder="Name"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && <div className={styles.error}>{errors.name}</div>}
          </div>

          <div className={styles.formGroup}>
            <input
              placeholder="Surname"
              type="text"
              id="surname"
              name="surname"
              value={formData.surname}
              onChange={handleInputChange}
            />
            {errors.name && <div className={styles.error}>{errors.name}</div>}
          </div>

          <div className={styles.formGroup}>
            <input
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && <div className={styles.error}>{errors.email}</div>}
          </div>

          <div className={styles.formGroup}>
            <input
              placeholder="Company"
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
            />
            {errors.name && <div className={styles.error}>{errors.name}</div>}
          </div>
          <div className={styles.formGroup}>
            <textarea
              placeholder="Message"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            />
            {/*<div className={styles.charCounter}>
              {formData.message.length} / 1000
            </div>
            {errors.message && (
              <div className={styles.error}>{errors.message}</div>
            )}
            </div>

          <div className={styles.formGroup}></div>

          <div className={styles.formGroup}>
            <input
              placeholder="Phone number with country code"
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            {errors.phoneNumber && (
              <div className={styles.error}>{errors.phoneNumber}</div>
            )}
          </div>

          <div className={styles.formGroupButton}>
            <button className={styles.submitButton} type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>*/}
    </div>
  );
};

export default ContactForm;
