// OpeningCompanies.tsx
import React from "react";
import talentImg from "../../assets/target.svg";
import styles from "../../styles/Landing Page styles/OpeningCompanies.module.css"; // Asegúrate de tener tu archivo de estilos
import { useTranslation } from "react-i18next";

interface OpeningCompaniesProps {
  // Puedes definir más propiedades según tus necesidades
}

const OpeningCompanies: React.FC<OpeningCompaniesProps> = () => {
  const [t, i18n] = useTranslation("global");

  const handleScroll = () => {
    const contactUsSection = document.getElementById("contact-us-section");
    if (contactUsSection) {
      const targetOffset = contactUsSection.offsetTop;
      const scrollDuration = 1000; // Duración del desplazamiento en milisegundos
      const start = window.pageYOffset;
      const startTime =
        "now" in window.performance ? performance.now() : new Date().getTime();

      const easeInOutCubic = (t: number) => {
        // Función de aceleración para suavizar el desplazamiento
        return t < 0.5
          ? 4 * t * t * t
          : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };

      const scroll = (currentTime: number) => {
        const currentTimeMS =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();
        const elapsed = currentTimeMS - startTime;

        window.scrollTo(
          0,
          easeInOutCubic(elapsed / scrollDuration) * (targetOffset - start) +
            start
        );

        if (elapsed < scrollDuration) {
          requestAnimationFrame(scroll);
        }
      };

      requestAnimationFrame(scroll);
    }
  };

  const videoLink =
    i18n.language === "de" ? "WFYE-Rt9xtY" : "WFYE-Rt9xtY";


  return (
    <div className={styles.openingCompanies}>
      <div className={styles.leftSection}>
        <div className={styles.textContent}>
          <h1>
            {t("companies-landing-page.opening-title-blue")}{" "}
            <span>{t("companies-landing-page.opening-title-black")}</span>
          </h1>
          <p>
            {t("companies-landing-page.opening-paragraph-normal1")}{" "}
            <span>{t("companies-landing-page.opening-paragraph-bold1")}</span>{" "}
            {t("companies-landing-page.opening-paragraph-normal2")}{" "}
            <span> {t("companies-landing-page.opening-paragraph-bold2")}</span>{" "}
            {t("companies-landing-page.opening-paragraph-normal3")}{" "}
            <span> {t("companies-landing-page.opening-paragraph-bold3")}</span>{" "}
            {t("companies-landing-page.opening-paragraph-normal4")}{" "}
            <span> {t("companies-landing-page.opening-paragraph-bold4")}</span>
            {t("companies-landing-page.opening-paragraph-normal5")}{" "}
            <span> {t("companies-landing-page.opening-paragraph-bold5")}</span>{" "}
            {t("companies-landing-page.opening-paragraph-normal6")}
          </p>
          <a href="#contact-us-section" onClick={handleScroll}>
            <button className={styles.buttonOpening}>
              {t("companies-landing-page.opening-button-book-demo")}
            </button>
          </a>
        </div>
      </div>
      <div className={styles.videoContainer}>
        <iframe
          src={`https://www.youtube.com/embed/${videoLink}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default OpeningCompanies;
