// LandingSection.tsx
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/CheckList.json";
import styles from "../../styles/Landing Page styles/LandingPage.module.css"; // Asegúrate de que la ruta sea correcta
import DemoWeb from "./DemoWeb";
import { useTranslation } from "react-i18next";

interface LandingSectionProps {
  // Puedes agregar más props según sea necesario
}

const LandingSection: React.FC<LandingSectionProps> = () => {
  const [t, i18n] = useTranslation("global");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={styles.contentContainer}>
      <div className={styles.landingSection}>
        <div className={styles.demoContainer}>
          <DemoWeb />
        </div>
        <div className={styles.textContent}>
          <h1>
            <span>
              {t("companies-landing-page.landing-section-title-black")}
            </span>
            {t("companies-landing-page.landing-section-title-blue")}{" "}
          </h1>
          <div className={styles.textContentParagraph}>
            <p>
              {t("companies-landing-page.landing-section-paragraph-normal1")}{" "}
              <span>
                {t("companies-landing-page.landing-section-paragraph-bold1")}{" "}
              </span>
              {t("companies-landing-page.landing-section-paragraph-normal2")}{" "}
              <span>
                {t("companies-landing-page.landing-section-paragraph-bold2")}{" "}
              </span>
              {t("companies-landing-page.landing-section-paragraph-normal3")}{" "}
              <span>
                {t("companies-landing-page.landing-section-paragraph-bold3")}{" "}
              </span>
              {t("companies-landing-page.landing-section-paragraph-normal4")}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;

/*// LandingSection.tsx
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/CheckList.json";
import styles from "../../styles/Landing Page styles/LandingPage.module.css"; // Asegúrate de que la ruta sea correcta
import DemoWeb from "./DemoWeb";

interface LandingSectionProps {
  // Puedes agregar más props según sea necesario
}

const LandingSection: React.FC<LandingSectionProps> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={styles.contentContainer}>
    <div className={styles.landingSection}>
      <div className={styles.lottieContainer}>
        <DemoWeb url="http://localhost:3000/moving" />
       
      </div>
      <div className={styles.textContent}>
        <h1>Welcome to Our <span>Landing Page</span></h1>
        
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel
          libero vel tortor consectetur accumsan.
        </p>
        <div className={styles.buttonsContainer}>
          <button className={styles.primaryButton}>Start now</button>
          <button className={styles.secondaryButton}> Get a free demo</button>
        </div>
        <div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default LandingSection;
*/
