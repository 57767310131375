import React, { useState, ChangeEvent, FormEvent } from "react";
import styles from "../styles/Calendly.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const CalendlyForMovers: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.contactUsLayout}>
      {/* Sección de CalendlyForMovers a la izquierda */}
      <div className={styles.calendlyContainer}>
        <h2>{t("contact-us-movers.calendly-title")}</h2>
        <p>{t("contact-us-movers.calendly-paragraph-normal")}</p>
        <p>
          {/* Enlace a WhatsApp */}
          <a
            href="https://api.whatsapp.com/message/YTBSRAKLMEWIE1?autoload=1&app_absent=0"
            className={styles.whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className={styles.whatsappIcon}
            />{" "}
            {t("contact-us-movers.calendly-whatsapp")}
          </a>
        </p>
        <div className={styles.calendlyContainer}>
          <iframe
            src="https://calendly.com/finli_one/45min"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default CalendlyForMovers;
