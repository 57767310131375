// tasksData.ts
import {
  ApartmentSearchDescription,
  CostOfLivingDescription,
  RecognitionProfesionalQualificationsDescription,
  ReviewInsuranceDescription,
  CancelContractsDescription,
  BankInvestmentDescription,
  RegisterHouseholdDescription,
  PetsWithYouDescription,
  ImportYourCarDescription,
  DeregistrationDescription,
  PostalMailDescription,
  FarewellDescription,
} from "./BeforeMovingTaskDescription";

import {
  ImportantDocumentsDescription,
  PurchaseVignetteDescription,
  CustomsOfficeDescription,
  DocumentsCarDescription,
} from "./MovingDayTaskDescription";

import {
  ChangeDriverLicenseDescription,
  ChildcareDescription,
  CurrencyExchangeDescription,
  EBankingTwintDescription,
  EducationSystemDescription,
  GetSwissInsuranceDescription,
  HealthInsuranceDescription,
  InformBanksOldCountryDescription,
  MobilityDescription,
  OnlineShoppingDescription,
  OpenBankAccountDescription,
  PhoneAndInternetDescription,
  RegisterCarDescription,
  RegisterPetsDescription,
  RegisterSwissDescription,
  ResidencePermitDescription,
  SerafeDescription,
  SupermarketsDescription,
  WasteDescription,
} from "./AfterTheMoveTaskDescription";

import {
  RetirementDescription,
  TaxDeclarationDescription,
} from "./AfterSettlingTaskDescription";

import {
  CertificateResidenceDescription,
  GeneralInformationDescription,
  HealthInsuranceCommuterDescription,
  InsurancesCommuterDescription,
  MobilePhoneCommuterDescription,
  OpenBankAccountCommuterDescription,
  RetirementCommuterDescription,
  WorkPermitDescription,
  TaxationCommuterDescription,
} from "./CommutingTaskDescription";
import {
  WeeklyApartmentSearchDescription,
  WeeklyCertificateResidenceDescription,
  WeeklyCurrencyExchangeDescription,
  WeeklyEBankingTwintDescription,
  WeeklyGeneralInformationDescription,
  WeeklyGetSwissInsuranceDescription,
  WeeklyHealthInsuranceCommuterDescription,
  WeeklyMobilityDescription,
  WeeklyOnlineShoppingDescription,
  WeeklyOpenBankDescription,
  WeeklyPhoneAndInternetDescription,
  WeeklyPickUpPermitDescription,
  WeeklyRecognitionProfesionalQualificationsDescription,
  WeeklyRegisterSwissDescription,
  WeeklyRetirementDescription,
  WeeklySerafeDescription,
  WeeklySupermarketsDescription,
  WeeklyTaxDeclarationDescription,
  WeeklyTaxationCommuterDescription,
  WeeklyWasteDescription,
} from "./WeeklyCommuting";

export interface Task {
  id: string;
  title: string;
  component: string;
  description: JSX.Element;
  completed: boolean;
  group: number;
  category: string;
  type: string;
}

export const groupNames: string[] = [
  "moving-details.group1",
  "moving-details.group2",
  "moving-details.group3",
  "moving-details.group4",
  "moving-details.group5",
];

//crear general y specifics de aqui abajo. Otra manera es crear default y los default ahi
export const initialTasks: Task[] = [
  // Group 1
  {
    id: "1",
    title: "tasks.group1.apartment-search-title", //"🏡 Apartment search",
    description: <ApartmentSearchDescription />,
    component: "ApartmentSearchDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "2",
    title: "tasks.group1.cost-of-living-title", //"💰 Cost of living",
    description: <CostOfLivingDescription />,
    component: "CostOfLivingDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "3",
    title: "tasks.group1.profesional-qualifications-title", //"📜 Recognition of Professional Qualifications",
    description: <RecognitionProfesionalQualificationsDescription />,
    component: "RecognitionProfesionalQualificationsDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },

  {
    id: "4",
    title: "tasks.group1.review-insurance-title", // "📄 Review, cancel, and adjust Insurances",
    description: <ReviewInsuranceDescription />,
    component: "ReviewInsuranceDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "5",
    title: "tasks.group1.cancel-contracts-title", //"🔌 Cancel contracts & Review documents",
    description: <CancelContractsDescription />,
    component: "CancelContractsDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "6",
    title: "tasks.group1.bank-investment-title", //"📈 Bank & Investment accounts",
    description: <BankInvestmentDescription />,
    component: "BankInvestmentDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "7",
    title: "tasks.group1.register-household-title", // "🚚 Register household goods and import duty-free",
    description: <RegisterHouseholdDescription />,
    component: "RegisterHouseholdDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "8",
    title: "tasks.group1.pets-title", //"🐶 Take pets with you",
    description: <PetsWithYouDescription />,
    component: "PetsWithYouDescription",
    completed: false,
    group: 1,
    category: "pets",
    type: "moving",
  },
  {
    id: "9",
    title: "tasks.group1.car-title", //"🚗 Import your car",

    description: <ImportYourCarDescription />,
    component: "ImportYourCarDescription",
    completed: false,
    group: 1,
    category: "car",
    type: "moving",
  },
  {
    id: "10",
    title: "tasks.group1.deregistration-title", //"🏪 Deregistration at the resident registration office",
    description: <DeregistrationDescription />,
    component: "DeregistrationDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "11",
    title: "tasks.group1.postal-mail-title", //"📬 Postal mail forwarding service",
    description: <PostalMailDescription />,
    component: "PostalMailDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },
  {
    id: "12",
    title: "tasks.group1.farewell-title", //"🥳 Farewell party",
    description: <FarewellDescription />,
    component: "FarewellDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "moving",
  },

  // Group 2
  {
    id: "13",
    title: "tasks.group2.important-documents-title", //"📃 Keep important documents ready",
    description: <ImportantDocumentsDescription />,
    component: "ImportantDocumentsDescription",

    completed: false,
    group: 2,
    category: "general",
    type: "moving",
  },
  {
    id: "14",
    title: "tasks.group2.vignette-title", //"🏁 Purchase a vignette",
    description: <PurchaseVignetteDescription />,
    component: "PurchaseVignetteDescription",
    completed: false,
    group: 2,
    category: "general",
    type: "moving",
  },
  {
    id: "15",
    title: "tasks.group2.customs-office-title", //"🕙 Customs office opening hours",
    description: <CustomsOfficeDescription />,
    component: "CustomsOfficeDescription",
    completed: false,
    group: 2,
    category: "general",
    type: "moving",
  },
  {
    id: "16",
    title: "tasks.group2.documents-car-title", //"🚗 Documents for importing your car",
    description: <DocumentsCarDescription />,
    component: "DocumentsCarDescription",
    completed: false,
    group: 2,
    category: "car",
    type: "moving",
  },

  // Group 3
  {
    id: "17",
    title: "tasks.group3.register-swiss-title", //"🏛️ Register with the Swiss Municipality",
    description: <RegisterSwissDescription />,
    component: "RegisterSwissDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "18",
    title: "tasks.group3.residence-permit-title", //"🪪 Residence Permit",
    description: <ResidencePermitDescription />,
    component: "ResidencePermitDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "19",
    title: "tasks.group3.open-bank-title", //"🏦 Open a Bank Account",
    description: <OpenBankAccountDescription />,
    component: "OpenBankAccountDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "20",
    title: "tasks.group3.currency-exchange-title", //"💸 Currency exchange ",
    description: <CurrencyExchangeDescription />,
    component: "CurrencyExchangeDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "21",
    title: "tasks.group3.phone-internet-title", //"📱 New Phone and Internet Contracts ",
    description: <PhoneAndInternetDescription />,
    component: "PhoneAndInternetDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "22",
    title: "tasks.group3.register-pets-title", //"🐶 Register and insure pets",
    description: <RegisterPetsDescription />,
    component: "RegisterPetsDescription",
    completed: false,
    group: 3,
    category: "pets",
    type: "moving",
  },
  {
    id: "23",
    title: "tasks.group3.health-insurance-title", //"🏥 Health Insurance",
    description: <HealthInsuranceDescription />,
    component: "HealthInsuranceDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "24",
    title: "tasks.group3.swiss-insurance-title", //"📑 Get Swiss Insurances",
    description: <GetSwissInsuranceDescription />,
    component: "GetSwissInsuranceDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "25",
    title: "tasks.group3.change-driver-license-title", //"🎫 Change Driver's License",
    description: <ChangeDriverLicenseDescription />,
    component: "ChangeDriverLicenseDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },

  {
    id: "27",
    title: "tasks.group3.ebanking-twint-title", //"🏦 Set up E-Banking & TWINT einrichten",
    description: <EBankingTwintDescription />,
    component: "EBankingTwintDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "28",
    title: "tasks.group3.inform-banks-old-country-title", //"📈 Inform banks in your old Country",
    description: <InformBanksOldCountryDescription />,
    component: "InformBanksOldCountryDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "29",
    title: "tasks.group3.mobility-title", //"🚌 Mobility",
    description: <MobilityDescription />,
    component: "MobilityDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "30",
    title: "tasks.group3.supermarkets-title", //"🛒 Supermarkets",
    description: <SupermarketsDescription />,
    component: "SupermarketsDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "31",
    title: "tasks.group3.childcare-title", //"👶 Childcare",
    description: <ChildcareDescription />,
    component: "ChildcareDescription",
    completed: false,
    group: 3,
    category: "kids",
    type: "moving",
  },
  {
    id: "32",
    title: "tasks.group3.education-system-title", //"🎓 Education system",
    description: <EducationSystemDescription />,
    component: "EducationSystemDescription",
    completed: false,
    group: 3,
    category: "kids",
    type: "moving",
  },
  {
    id: "33",
    title: "tasks.group3.online-shopping-title", //"🪑 Online Shopping",
    description: <OnlineShoppingDescription />,
    component: "OnlineShoppingDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "34",
    title: "tasks.group3.serafe-title", //"📺 Serafe (Radio&TV Fee)",
    description: <SerafeDescription />,
    component: "SerafeDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "35",
    title: "tasks.group3.waste-title", //"🗑️ Waste Disposal System",
    description: <WasteDescription />,
    component: "WasteDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "moving",
  },
  {
    id: "36",
    title: "tasks.group3.register-car-title", //"🚗 Register your car",

    description: <RegisterCarDescription />,
    component: "RegisterCarDescription",
    completed: false,
    group: 3,
    category: "car",
    type: "moving",
  },

  // Group 4
  {
    id: "37",
    title: "tasks.group4.retirement-title", //"💰 Start with private retirement planning",
    description: <RetirementDescription />,
    component: "RetirementDescription",
    completed: false,
    group: 4,
    category: "general",
    type: "moving",
  },
  {
    id: "38",
    title: "tasks.group4.tax-declaration-title", //"💱 Tax Declaration",
    description: <TaxDeclarationDescription />,
    component: "TaxDeclarationDescription",
    completed: false,
    group: 4,
    category: "general",
    type: "moving",
  },

  //commuting
  {
    id: "39",
    title: "tasks.commuting.general-information-title", //"😲 General Information"
    description: <GeneralInformationDescription />,
    component: "GeneralInformationDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "40",
    title: "tasks.commuting.certificate-residence-title", // "📃 Apply for a Certificate of Residence and Submit it to the Tax Office ",
    description: <CertificateResidenceDescription />,
    component: "CertificateResidenceDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "41",
    title: "tasks.commuting.work-permit-title", // "📃 Apply for a Certificate of Residence and Submit it to the Tax Office ",
    description: <WorkPermitDescription />,
    component: "WorkPermitDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "42",
    title: "tasks.commuting.open-bank-title", // "🏦 Bank Account for Grenzgänger",
    description: <OpenBankAccountCommuterDescription />,
    component: "OpenBankAccountCommuterDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "43",
    title: "tasks.commuting.mobile-phone-title", // "📱 Choosing a Mobile Phone Provider for Switzerland, Germany, and Europe",
    description: <MobilePhoneCommuterDescription />,
    component: "MobilePhoneCommuterDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "44",
    title: "tasks.commuting.health-insurance-title", // "📱 Choosing a Mobile Phone Provider for Switzerland, Germany, and Europe",
    description: <HealthInsuranceCommuterDescription />,
    component: "HealthInsuranceCommuterDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "45",
    title: "tasks.commuting.insurance-commuters-title", // "📱 Choosing a Mobile Phone Provider for Switzerland, Germany, and Europe",
    description: <InsurancesCommuterDescription />,
    component: "InsurancesCommuterDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "46",
    title: "tasks.commuting.retirement-title", // "💰 Retirement planning",
    description: <RetirementCommuterDescription />,
    component: "RetirementCommuterDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "47",
    title: "tasks.commuting.taxation-title", // "📱 Choosing a Mobile Phone Provider for Switzerland, Germany, and Europe",
    description: <TaxationCommuterDescription />,
    component: "TaxationCommuterDescription",
    completed: false,
    group: 5,
    category: "general",
    type: "daily-commuting",
  },
  {
    id: "48",
    title: "tasks.weekly-commuting.general-information-title",
    description: <WeeklyGeneralInformationDescription />,
    component: "WeeklyGeneralInformationDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "49",
    title: "tasks.weekly-commuting.work-permit-title",
    description: <WeeklyCertificateResidenceDescription />,
    component: "WeeklyCertificateResidenceDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "50",
    title: "tasks.weekly-commuting.health-insurance-title",
    description: <WeeklyHealthInsuranceCommuterDescription />,
    component: "WeeklyHealthInsuranceCommuterDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "51",
    title: "tasks.weekly-commuting.taxation-title",
    description: <WeeklyTaxationCommuterDescription />,
    component: "WeeklyTaxationCommuterDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "52",
    title: "tasks.weekly-commuting.apartment-search-title",
    description: <WeeklyApartmentSearchDescription />,
    component: "WeeklyApartmentSearchDescription",
    completed: false,
    group: 1,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "53",
    title: "tasks.weekly-commuting.pick-up-permit-title",
    description: <WeeklyPickUpPermitDescription />,
    component: "WeeklyPickUpPermitDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "54",
    title: "tasks.weekly-commuting.register-swiss-title",
    description: <WeeklyRegisterSwissDescription />,
    component: "WeeklyRegisterSwissDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "55",
    title: "tasks.weekly-commuting.open-bank-title",
    description: <WeeklyOpenBankDescription />,
    component: "WeeklyOpenBankDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "56",
    title: "tasks.weekly-commuting.currency-exchange-title",
    description: <WeeklyCurrencyExchangeDescription />,
    component: "WeeklyCurrencyExchangeDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "57",
    title: "tasks.weekly-commuting.phone-internet-title",
    description: <WeeklyPhoneAndInternetDescription />,
    component: "WeeklyPhoneAndInternetDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "58",
    title: "tasks.weekly-commuting.swiss-insurance-title",
    description: <WeeklyGetSwissInsuranceDescription />,
    component: "WeeklyGetSwissInsuranceDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "59",
    title: "tasks.weekly-commuting.profesional-qualifications-title",
    description: <WeeklyRecognitionProfesionalQualificationsDescription />,
    component: "WeeklyRecognitionProfesionalQualificationsDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "60",
    title: "tasks.weekly-commuting.ebanking-twint-title",
    description: <WeeklyEBankingTwintDescription />,
    component: "WeeklyEBankingTwintDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "61",
    title: "tasks.weekly-commuting.mobility-title",
    description: <WeeklyMobilityDescription />,
    component: "WeeklyMobilityDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "62",
    title: "tasks.weekly-commuting.supermarkets-title",
    description: <WeeklySupermarketsDescription />,
    component: "WeeklySupermarketsDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "63",
    title: "tasks.weekly-commuting.online-shopping-title",
    description: <WeeklyOnlineShoppingDescription />,
    component: "WeeklyOnlineShoppingDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "64",
    title: "tasks.weekly-commuting.serafe-title",
    description: <WeeklySerafeDescription />,
    component: "WeeklySerafeDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "65",
    title: "tasks.weekly-commuting.waste-title",
    description: <WeeklyWasteDescription />,
    component: "WeeklyWasteDescription",
    completed: false,
    group: 3,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "66",
    title: "tasks.weekly-commuting.retirement-title",
    description: <WeeklyRetirementDescription />,
    component: "WeeklyRetirementDescription",
    completed: false,
    group: 4,
    category: "general",
    type: "weekly-commuting",
  },
  {
    id: "67",
    title: "tasks.weekly-commuting.tax-declaration-title",
    description: <WeeklyTaxDeclarationDescription />,
    component: "WeeklyTaxDeclarationDescription",
    completed: false,
    group: 4,
    category: "general",
    type: "weekly-commuting",
  },
];
