// CookiesSettings.tsx
import React from "react";
import styles from "../styles/CookiesSettings.module.css"; // Asegúrate de que la ruta sea correcta
import { useTranslation } from "react-i18next";

const CookiesSettings: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.containerCookies}>
      <h1 className={styles.headerCookies}>{t("cookies.title")}</h1>

      {/* Sección 1 */}
      <div className={styles.sectionCookies}>
        <h2 className={styles.subHeaderCookies}>
          {t("cookies.title-section-A")}
        </h2>
        <p className={styles.paragraphCookies}>
          {t("cookies.section-A-paragraph1")}
        </p>
      </div>

      {/* Sección 2 */}
      <div className={styles.sectionCookies}>
        <h2 className={styles.subHeaderCookies}>
          {t("cookies.title-section-B")}
        </h2>
        <p className={styles.paragraphCookies}>
          {t("cookies.section-B-paragraph1")}
        </p>
      </div>

      <div className={styles.sectionCookies}>
        <h2 className={styles.subHeaderCookies}>
          {t("cookies.title-section-C")}
        </h2>

        <h2 className={styles.subHeaderCookies}>
          {t("cookies.subtitle-section-C")}
        </h2>
        <p className={styles.paragraphCookies}>
          {t("cookies.section-C-paragraph1")}
        </p>
        <h2 className={styles.subHeaderCookies}>
          {t("cookies.title-section-D")}
        </h2>

        <p className={styles.paragraphCookies}>
          {t("cookies.section-D-paragraph1")}
        </p>
        <p className={styles.paragraphCookies}>
          {t("cookies.section-D-paragraph2")}
          <a
            href="http://www.finli.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.finli.ch{""}
          </a>{" "}
          {t("cookies.section-D-paragraph3")}
          <a
            href="http://www.finli.one"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.finli.one{""}
          </a>
        </p>
      </div>
    </div>
  );
};

export default CookiesSettings;
