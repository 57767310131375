// ProgressBar.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faHouseChimney,
  faPlaneArrival,
  faHandsClapping,
  faFlagCheckered,
  faRocket,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/ProgressBar.module.css"; // Ajusta la ruta según tu estructura de archivos
import { faGolang, faRProject } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

interface ProgressBarProps {
  totalTasks: number;
  completedTasks: number;
}

const getMotivationalMessage = (completionPercentage: number) => {
  let icon = faArrowDown;

  if (completionPercentage === 0) {
    return {
      message: "progress-bar.motivation-message-start",
      icon: faRocket,
    };
  } else if (completionPercentage < 25) {
    return {
      message: "progress-bar.motivation-message-keep-going",
      icon: faPlaneDeparture,
    };
  } else if (completionPercentage < 50) {
    return {
      message: "progress-bar.motivation-message-almost-half",
      icon: faHouseChimney,
    };
  } else if (completionPercentage < 60) {
    return {
      message: "progress-bar.motivation-message-halfway",
      icon: faHouseChimney,
    };
  } else if (completionPercentage < 75) {
    return {
      message: "progress-bar.motivation-message-almost-there",
      icon: faPlaneArrival,
    };
  } else if (completionPercentage < 100) {
    return {
      message: "progress-bar.motivation-message-finish-line",
      icon: faFlagCheckered,
    };
  } else {
    return {
      message: "progress-bar.motivation-message-congratulations",
      icon: faHandsClapping,
    };
  }
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  totalTasks,
  completedTasks,
}) => {
  const [t, i18n] = useTranslation("global");

  const completionPercentage =
    totalTasks !== 0 ? (completedTasks / totalTasks) * 100 : 0;
  const { message, icon } = getMotivationalMessage(completionPercentage);

  const markers = Array.from({ length: totalTasks + 1 }).map((_, index) => (
    <div
      key={index}
      className={styles.progressBarMarker}
      style={{ left: `${index * (100 / totalTasks - 0.5)}%` }}
    ></div>
  ));

  return (
    <>
      <div className={styles.completionMessage}>
        <FontAwesomeIcon icon={icon} className={styles.icon} />
        {t(message)}
      </div>
      <>
        <div className={styles.percentageProgressBarContainer}>
          <span
            className={styles.percentageLabel}
          >{`${completionPercentage.toFixed(2)}%`}</span>
        </div>
      </>
    </>
  );
};

export default ProgressBar;
