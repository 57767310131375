import React from "react";
import styles from "../styles/Impressum.module.css";
import { useTranslation } from "react-i18next";

const Impressum: React.FC = () => {
  const [t, i18] = useTranslation("global");

  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2711.1785706062915!2d8.7960424!3d47.1935173!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ab6a6955876cb%3A0x91839b85495ffabc!2sTalstrasse%2088%2C%208808%20Freienbach!5e0!3m2!1sen!2sch!4v1709836812934!5m2!1sen!2sch";

  return (
    <div className={styles.containerImpressum}>
      <h1 className={styles.titleImpressum}>{t("impressum.title")} </h1>
      <p className={styles.contentImpressum}>
        {t("impressum.paragraph1")} <br />
        {t("impressum.paragraph2")} <br />
        {t("impressum.paragraph3")} <br />
        {t("impressum.paragraph4")}{" "}
        <a href="mailto:info@finli.ch">{t("impressum.email-link")}</a>
      </p>
      {/* Iframe para embeber el mapa */}
      <div className={styles.mapContainer}>
        <iframe
          title="Map"
          className={styles.mapImpressum}
          src={mapUrl}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>{" "}
    </div>
  );
};

export default Impressum;
