import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./styles/styles.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import global_en from "../src/translations/en/global.json"
import global_de from "../src/translations/de/global.json"

i18next.init({
  interpolation: {escapeValue: false},
  lng:"en",
  resources: {
    en: {
      global: global_en
    },
    de: {
      global: global_de
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
