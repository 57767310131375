// MainPage.tsx
import styles from "../styles/MainPage.module.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import findTalentImg from "../assets/findTalent.svg";
import moveAbroadImg from "../assets/moveAbroad.svg";
import moveApp from "../assets/MoverPhone.png";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const MainPage: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const [isLeftHovered, setLeftHovered] = useState(false);
  const [isRightHovered, setRightHovered] = useState(false);

  const navigate = useNavigate();

  return (
    <div className={styles.mainPage}>
      <div
        className={`${styles.section} ${styles.leftSection} ${
          isLeftHovered ? styles.hovered : ""
        }`}
        onMouseEnter={() => setLeftHovered(true)}
        onMouseLeave={() => setLeftHovered(false)}
      >
        <div className={styles.contentLeft}>
          <h2>{t("main-page.companies-pre-title")}</h2>
          <h1>{t("main-page.companies-title")}</h1>
          <p>{t("main-page.companies-subtitle")}</p>
          <div className={styles.centeredButtonContainer}>
            <button
              className={styles.mainPageButton}
              onClick={() => navigate("/companies-landing-page")}
            >
              {t("main-page.companies-button")}
            </button>
          </div>
          <div>
            <img
              src={
                findTalentImg
              } /*src={isLeftHovered ? moveApp : findTalentImg}*/
              alt="Descripción de la imagen"
              className={styles.contentImage}
            />
          </div>
        </div>
      </div>

      <div
        className={`${styles.section} ${styles.rightSection} ${
          isRightHovered ? styles.hovered : ""
        }`}
        onMouseEnter={() => setRightHovered(true)}
        onMouseLeave={() => setRightHovered(false)}
      >
        <div className={styles.contentRight}>
          <h2>{t("main-page.movers-pre-title")}</h2>
          <h1>{t("main-page.movers-title")} </h1>
          <p>{t("main-page.movers-subtitle")}</p>
          <div className={styles.centeredButtonContainer}>
            <button
              className={styles.mainPageButton}
              onClick={() => navigate("/home")}
            >
              {t("main-page.movers-button")}
            </button>
          </div>
          <div>
            <img
              src={
                moveAbroadImg
              } /*src={isRightHovered ? findTalentImg : moveAbroadImg}*/
              alt="Descripción de la imagen"
              className={styles.contentImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
