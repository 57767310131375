// HomeTextImage.tsx
import React from "react";

import welcomeImage from "../../assets/zh-img.jpg";
import styles from "../../styles/Home styles/HomeTextImage.module.css";
import NavigationButtons from "./NavigationButtons";
import { useTranslation } from "react-i18next";

const HomeTextImage = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.textImageContainer}>
      <div className={styles.textSection}>
        <div>
          <h1>{t("home.text-image-title")}</h1>
          <p>{t("home.text-image-paragraph")}</p>
        </div>
        <NavigationButtons />
      </div>
      <div className={styles.imageSection}>
        <img src={welcomeImage} alt="Welcome Image" />
      </div>
    </div>
  );
};

export default HomeTextImage;
