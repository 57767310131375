// InstructionTypes.tsx
import React, { useState, useEffect } from "react";
import styles from "../../styles/Landing Page styles/InstructionsContainer.module.css";
import instructionImg1 from "../../assets/instructionTypesImg1.png";
import instructionImg2 from "../../assets/instructionTypesImg2.png";
import instructionImg3 from "../../assets/instructionTypesImg3.png";
import instructionImg4 from "../../assets/instructionTypesImg4.png";
import instructionImg1DE from "../../assets/instructionTypesImg1-de.png";
import instructionImg2DE from "../../assets/instructionTypesImg2-de.png";
import instructionImg3DE from "../../assets/instructionTypesImg3-de.png";
import instructionImg4DE from "../../assets/instructionTypesImg4-de.png";
import { useTranslation } from "react-i18next";

const InstructionTypes: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1150);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1150);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.instructionsContainer}>
      <div className={styles.instructionsContent}>
        <h2>{t("companies-landing-page.instructions-type-title-blue")}</h2>
        <div className={styles.instructionImage}>
          {isSmallScreen ? (
            <>
              <img
                className={styles.instructionImage}
                src={
                  i18n.language === "de" ? instructionImg2DE : instructionImg2
                }
                alt="Instruction Image 2"
              />
              <img
                className={styles.instructionImage}
                src={
                  i18n.language === "de" ? instructionImg3DE : instructionImg3
                }
                alt="Instruction Image 3"
              />
              <img
                className={styles.instructionImage}
                src={
                  i18n.language === "de" ? instructionImg4DE : instructionImg4
                }
                alt="Instruction Image 3"
              />
            </>
          ) : (
            <img
              className={styles.instructionImage}
              src={i18n.language === "de" ? instructionImg1DE : instructionImg1}
              alt="Instruction Image 1"
            />
          )}
        </div>
      </div>
      
    </div>
  );
};

export default InstructionTypes;
