// Footer.tsx
import React from "react";
import styles from "../styles/Footer.module.css"; // Asume que tienes un archivo de estilos
import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRocket,
  faComment,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <footer className={styles.footer}>
      <div className={styles.footerLogoSection}>
        <img src={logo} alt="Logo" className={styles.logoFooter} />
        <div className={styles.buttonContainer}>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <button className={styles.footerButtonLeft}>
              <FontAwesomeIcon icon={faRocket} className={styles.icon} />
              {t("footer.lets-start-button")}
            </button>
          </Link>

          {/*<button className={styles.footerButtonLeft}>
            <FontAwesomeIcon icon={faComment} className={styles.icon} />
            {t("footer.lets-chat-button")}
  </button>*/}
          <Link to="/contact-us-movers" style={{ textDecoration: "none" }}>
            <button className={styles.footerButtonRight}>
              <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
              {t("footer.contact-us-button")}
            </button>
          </Link>
        </div>
      </div>

      <div className={styles.sectionList}>
        <div className={styles.footerList}>
          <ul>
            <h3>{t("footer.menu-title")}</h3>
            <li>
              <a href="/">{t("footer.menu-home")}</a>
            </li>
            {/*<li>
              <a href="/about">{t("footer.menu-about")}</a>
              </li>*/}
            <li>
              <a href="/contact-us-movers">{t("footer.menu-contact")}</a>
            </li>
            <li>
              <a href="/impressum">{t("footer.menu-impressum")}</a>
            </li>
          </ul>
        </div>
        <div className={styles.footerList}>
          <ul>
            <h3>{t("footer.services-title")}</h3>
            <li>
              <a href="/companies-landing-page">
                {t("footer.services-company")}
              </a>
            </li>
            <li>
              <a href="/home">{t("footer.services-mover")}</a>
            </li>
          </ul>
        </div>
        <div className={styles.footerList}>
          <ul>
            <h3>{t("footer.resources-title")}</h3>
            <li>
              <a href="/privacy-policy">
                {t("footer.resources-privacy-policy")}
              </a>
            </li>

            <li>
              <a href="/cookies-settings">{t("footer.resources-cookies")}</a>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.section}>
        {/* Contenido de la sección 3 */}
        <p>
          &copy; {new Date().getFullYear()} {t("footer.rights-reserved")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
