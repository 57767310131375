import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/Moving styles/Commuting.module.css"; // Asegúrate de que la ruta sea correcta
import commutingImage from "../../assets/commutingMoto.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faArrowRight,
  faSave,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Commuting: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const [type, setType] = useState(localStorage.getItem("type") || "");
  const [from, setFrom] = useState(localStorage.getItem("from") || "");
  const [to, setTo] = useState(localStorage.getItem("to") || "");
  const [approxDate, setApproxDate] = useState("");
  const [unknownDates, setUnknownDates] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [movingDetails, setMovingDetails] = useState({
    partner: false,
    pets: false,
    kids: false,
    euCitizen: false,
    car: false,
    general: true,
  });

  const typeCommuting = [
    { id: "daily-commuting", name: "commuting.type-commuting-daily" },
    { id: "weekly-commuting", name: "commuting.type-commuting-weekly" },
  ];
  // Ejemplo de países para seleccionar
  const countriesOrigin = [
    { id: "de", name: "commuting.from-germany" },
    { id: "au", name: "commuting.from-austria" },
  ];

  const countriesDestination = [
    { id: "ch", name: "commuting.to-switzerland" },
    //{ id: "us", name: "United States" },
  ];

  const handleValidation = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    if (!type) newErrors.type = "commuting.error-type";
    if (!from) newErrors.from = "commuting.error-from";
    if (!to) newErrors.to = "commuting.error-to";
    if (!approxDate && !unknownDates)
      newErrors.approxDate = "commuting.error-date";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    localStorage.setItem("from", type);
    localStorage.setItem("from", from);
    localStorage.setItem("to", to);
  }, [from, to]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (handleValidation()) {
      // add here the reset
      localStorage.setItem("selectedUserInfo", JSON.stringify(movingDetails));
      localStorage.setItem("type", type);
      localStorage.setItem("from", from);
      localStorage.setItem("to", to);
      localStorage.setItem("lastPath", location.pathname);
      navigate("/moving-details");
    }
  };

  return (
    <div className={styles.commutingFormPageContainer}>
      <div className={styles.commutingFormContainer}>
        <h1>{t("commuting.title")}</h1>
        <div>
          <div className={styles.dropdown}>
            <label htmlFor="type">{t("commuting.type")}</label>
            <select
              id="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              className={styles.selected}
            >
              <option value="">{t("commuting.type-selection")}</option>
              {typeCommuting.map((type) => (
                <option key={type.id} value={type.id}>
                  {t(type.name)}
                </option>
              ))}
            </select>
          </div>
          {errors.type && (
            <div className={styles.errorContainer}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className={styles.errorIcon}
              />
              <p className={styles.error}>{t(errors.type)}</p>
            </div>
          )}
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown}>
            <label htmlFor="from">{t("commuting.from-subtitle")}</label>
            <select
              id="from"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className={styles.selected}
            >
              <option value="">{t("commuting.from-selection")}</option>
              {countriesOrigin.map((country) => (
                <option key={country.id} value={country.id}>
                  {t(country.name)}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.dropdown}>
            <label htmlFor="to">{t("commuting.to-subtitle")}</label>
            <select
              id="to"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className={styles.selected}
            >
              <option value="">{t("commuting.to-selection")}</option>
              {countriesDestination.map((country) => (
                <option key={country.id} value={country.id}>
                  {t(country.name)}
                </option>
              ))}
            </select>
          </div>
        </div>
        {errors.from && (
          <div className={styles.errorContainer}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={styles.errorIcon}
            />
            <p className={styles.error}>{t(errors.from)}</p>
          </div>
        )}
        {errors.to && (
          <div className={styles.errorContainer}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={styles.errorIcon}
            />
            <p className={styles.error}>{t(errors.to)}</p>
          </div>
        )}
        <div className={styles.dateContainer}>
          <label htmlFor="approxDate">{t("moving.date-subtitle")}</label>
          <input
            type="date"
            id="approxDate"
            value={approxDate}
            onChange={(e) => setApproxDate(e.target.value)}
          />
        </div>
        <div className="checkboxContainer">
          <label className="checkboxLabel">
            <input
              type="checkbox"
              checked={unknownDates}
              onChange={(e) => setUnknownDates(e.target.checked)}
            />
            {t("commuting.date-not-clear-selection")}{" "}
          </label>
        </div>
        <div className={styles.informationContainer}>
          {errors.approxDate && (
            <div className={styles.errorContainer}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className={styles.errorIcon}
              />
              <p className={styles.error}>{t(errors.approxDate)}</p>
            </div>
          )}
          <div className={styles.buttonsContainer}>
            <button onClick={handleSubmit} className={styles.nextStepButton}>
              <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />{" "}
              {t("commuting.next-step-button")}{" "}
            </button>
            <button
              onClick={() => navigate("/moving")}
              className={styles.buttonGoBack}
            >
              <FontAwesomeIcon
                icon={faTruckFast}
                className={styles.icon}
              />{" "}
              {t("commuting.moving-not-commuting-button")}{" "}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.commutingImageContainer}>
        <img src={commutingImage} alt="Login" className={styles.loginImage} />
      </div>
    </div>
  );
};

export default Commuting;
