import React from "react";
import { useTranslation } from "react-i18next";

// TASK APARTMENT SEARCH
export const ApartmentSearchDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.group1.apartment-search-paragraph1")}</p>

      <p>
        <strong>{t("tasks.group1.apartment-search-paragraph2-bold")}</strong>
      </p>
      <ul>
        <li>
          <a
            href="https://www.homegate.ch/de"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.apartment-search-list1-item1")}
          </a>
        </li>
        <li>
          <a
            href="https://www.homegate.ch/de"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.apartment-search-list1-item2")}
          </a>
        </li>
      </ul>

      <p>
        <strong>{t("tasks.group1.apartment-search-paragraph3-bold")}</strong>
      </p>
      <ul>
        <li>
          <strong>{t("tasks.group1.apartment-search-list2-item1-bold")}</strong>{" "}
          {t("tasks.group1.apartment-search-list2-item1-normal")}
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list2-item2-bold")}</strong>{" "}
          {t("tasks.group1.apartment-search-list2-item2-normal")}
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list2-item3-bold")}</strong>
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list2-item4-bold")}</strong>{" "}
          {t("tasks.group1.apartment-search-list2-item4-normal")}
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list2-item5-bold")}</strong>{" "}
          {t("tasks.group1.apartment-search-list2-item5-normal")}
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list2-item6-bold")}</strong>{" "}
          {t("tasks.group1.apartment-search-list2-item6-normal")}
        </li>
        <li>
          <strong>{t("tasks.group1.apartment-search-list2-item7-bold")}</strong>{" "}
          {t("tasks.group1.apartment-search-list2-item7-normal")}
        </li>
      </ul>

      <p>
        <strong>{t("tasks.group1.apartment-search-paragraph4-bold")}</strong>
        {t("tasks.group1.apartment-search-paragraph4-normal")}
      </p>

      <p>
        <strong>{t("tasks.group1.apartment-search-paragraph5-bold")}</strong>
        {t("tasks.group1.apartment-search-paragraph5-normal")}
      </p>
    </div>
  );
};

// TASK COST OF LIVING
export const CostOfLivingDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.cost-of-living-paragraph1")}</p>

      <p>
        <strong> {t("tasks.group1.cost-of-living-paragraph2-bold")} </strong>
      </p>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://budgetberatung.ch/budgetbeispiele#c174"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.cost-of-living-list1-item1")}
          </a>
        </li>
        <li>
          <a
            href="https://budgetberatung.ch/budgetrechner"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.cost-of-living-list1-item2")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK RECOGNITION OF PROF

export const RecognitionProfesionalQualificationsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.profesional-qualifications-paragraph1")}</p>

      <p>
        <strong>
          {t("tasks.group1.profesional-qualifications-paragraph2-bold")}
        </strong>
      </p>

      <ul>
        <li>
          <p>
            <strong>
              {" "}
              {t(
                "tasks.group1.profesional-qualifications-list1-item1-bold"
              )}{" "}
            </strong>
            <a
              href="
            https://www.bag.admin.ch/bag/de/home/berufe-im-gesundheitswesen/auslaendische-abschluesse-gesundheitsberufe.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.profesional-qualifications-list1-item1-link1")}
            </a>
            ,{" "}
            <a
              href="https://www.bag.admin.ch/bag/de/home/berufe-im-gesundheitswesen/auslaendische-abschluesse-gesundheitsberufe/diplome-der-medizinalberufe-aus-staaten-der-eu-efta.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.profesional-qualifications-list1-item1-link2")}{" "}
            </a>
          </p>
        </li>
        <li>
          {" "}
          <strong>
            {" "}
            {t("tasks.group1.profesional-qualifications-list1-item2-bold")}{" "}
          </strong>
          <a
            href="https://www.edk.ch/de/themen/diplomanerkennung"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.profesional-qualifications-list1-item2-link")}
          </a>
        </li>

        <li>
          {" "}
          <strong>
            {" "}
            {t("tasks.group1.profesional-qualifications-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.group1.profesional-qualifications-list1-item3-1")}
          <a
            href="https://verwaltung.bund.de/leistungsverzeichnis/DE/leistung/99018033012000/herausgeber/HE-344488931/region/06"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.profesional-qualifications-list1-item3-link")}
          </a>{" "}
          {t("tasks.group1.profesional-qualifications-list1-item3-3")}{" "}
        </li>
        <li>
          {" "}
          <strong>
            {" "}
            {t("tasks.group1.profesional-qualifications-list1-item4-bold")}{" "}
          </strong>
          <a
            href="https://www.edk.ch/de/themen/diplomanerkennung"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.profesional-qualifications-list1-item4-link")}
          </a>
        </li>
        <li>
          {" "}
          <strong>
            {" "}
            {t("tasks.group1.profesional-qualifications-list1-item5-bold")}{" "}
          </strong>
          <a
            href="https://www.sbfi.admin.ch/sbfi/de/home/bildung/diploma.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.profesional-qualifications-list1-item5-link")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const ReviewInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.review-insurance-paragraph1")}
        <br />
        <br />
        <span>{t("tasks.group1.review-insurance-bold2")}</span>
        {t("tasks.group1.review-insurance-paragraph2")}
      </p>
    </div>
  );
};

export const CancelContractsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.cancel-contracts-paragraph1")}</p>

      <p>
        <strong> {t("tasks.group1.cancel-contracts-paragraph2-bold")}</strong>
      </p>
      <ul>
        <li> {t("tasks.group1.cancel-contracts-list1-item1")} </li>
        <li> {t("tasks.group1.cancel-contracts-list1-item2")} </li>
        <li> {t("tasks.group1.cancel-contracts-list1-item3")} </li>
        <li> {t("tasks.group1.cancel-contracts-list1-item4")} </li>
        <li> {t("tasks.group1.cancel-contracts-list1-item5")} </li>
        <li> {t("tasks.group1.cancel-contracts-list1-item6")} </li>
        <li> {t("tasks.group1.cancel-contracts-list1-item7")} </li>
        <li> {t("tasks.group1.cancel-contracts-list1-item8")} </li>
      </ul>

      <br />

      <p>
        <strong>{t("tasks.group1.cancel-contracts-paragraph3-bold")} </strong>
        {t("tasks.group1.cancel-contracts-paragraph3")}
      </p>
    </div>
  );
};

export const BankInvestmentDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.bank-investment-paragraph1")}
        <br />
        <br />
        <strong>{t("tasks.group1.bank-investment-paragraph2-bold")}</strong>
        {t("tasks.group1.bank-investment-paragraph2")}
      </p>
    </div>
  );
};

export const RegisterHouseholdDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.register-household-paragraph1")}

        <ul style={{ listStyleType: "none" }}>
          <li>
            <a
              href="https://www.bazg.admin.ch/dam/bazg/de/dokumente/verfahren-betrieb/Aufgabenvollzug/Formulare/18_44_uebersiedlungsgut.pdf.download.pdf/18_44_uebersiedlungsgut.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.register-household-list1-item1")}
            </a>
          </li>
          <li>
            <a
              href="https://www.bazg.admin.ch/bazg/de/home/information-private/reisen-und-einkaufen--freimengen-und-wertfreigrenze/einfuhr-in-die-schweiz.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.register-household-list1-item2")}
            </a>
          </li>
        </ul>
      </p>
    </div>
  );
};

export const PetsWithYouDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.pets-paragraph1")}</p>
      <p>
        <strong>{t("tasks.group1.pets-list1-bold")} </strong>
      </p>
      <ul>
        <li>
          {t("tasks.group1.pets-list1-item1-bold")}{" "}
          <strong>{t("tasks.group1.pets-list1-item1")} </strong>
        </li>
        <li>
          <strong>{t("tasks.group1.pets-list1-item2-bold")} </strong>
          {t("tasks.group1.pets-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.group1.pets-list1-item3-bold")} </strong>{" "}
          {t("tasks.group1.pets-list1-item3")}
        </li>
        <li>
          <strong>{t("tasks.group1.pets-list1-item4-bold")} </strong>
          {t("tasks.group1.pets-list1-item4")}
        </li>
        <li>
          <strong>{t("tasks.group1.pets-list1-item5-bold")} </strong>{" "}
          {t("tasks.group1.pets-list1-item5")}
        </li>
        <li>
          <strong>{t("tasks.group1.pets-list1-item6-bold")}</strong>{" "}
          {t("tasks.group1.pets-list1-item6")}
        </li>
        <li>
          {t("tasks.group1.pets-list1-item7")}{" "}
          <strong>{t("tasks.group1.pets-list1-item7-bold")} </strong>
        </li>
      </ul>
      <p>
        <strong>{t("tasks.group1.pets-list2-bold")} </strong>
      </p>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.vier-pfoten.ch/unseregeschichten/ratgeber/in-welchem-kanton-sind-welche-hunderassen-erlaubt"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.group1.pets-list2-item1")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const ImportYourCarDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.car-paragraph1")}

        <br />
        <br />

        <strong> {t("tasks.group1.car-paragraph2-bold")}</strong>

        {t("tasks.group1.car-paragraph2")}
        <br />
        <br />
        <ul style={{ listStyleType: "none" }}>
          <li>
            <a
              href="https://www.bazg.admin.ch/dam/bazg/de/dokumente/verfahren-betrieb/Aufgabenvollzug/Formulare/18_44_uebersiedlungsgut.pdf.download.pdf/18_44_uebersiedlungsgut.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.car-list1-item1")}
            </a>
          </li>
          <li>
            <a
              href="https://www.bazg.admin.ch/bazg/de/home/information-private/reisen-und-einkaufen--freimengen-und-wertfreigrenze/einfuhr-in-die-schweiz.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.car-list1-item2")}
            </a>
          </li>
        </ul>
      </p>
    </div>
  );
};

export const DeregistrationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.deregistration-paragraph1")}
        <br />
        <br />
        <strong>{t("tasks.group1.deregistration-paragraph2-bold")}</strong>
        {t("tasks.group1.deregistration-paragraph2")}
      </p>
    </div>
  );
};

export const PostalMailDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group1.postal-mail-paragraph1")}

        <ul style={{ listStyleType: "none" }}>
          <li>
            <a
              href="https://www.deutschepost.de/de/n/nachsendeservice.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.postal-mail-list1-item1")}
            </a>
          </li>
          <li>
            <a
              href="https://www.post.at/p/a/nachsenden"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("tasks.group1.postal-mail-list1-item2")}
            </a>
          </li>
        </ul>
      </p>
    </div>
  );
};

export const FarewellDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group1.farewell-paragraph1")}</p>
    </div>
  );
};
