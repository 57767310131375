import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT DOCUMENTS READY
export const WeeklyGeneralInformationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <ul>
        <li>
          {t("tasks.weekly-commuting.general-information-list1-item1-part1")}{" "}
          <strong>
            {t("tasks.weekly-commuting.general-information-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.general-information-list1-item1-part2")}{" "}
        </li>
        <li>
          {t("tasks.weekly-commuting.general-information-list1-item2-part1")}
          <strong>
            {t("tasks.weekly-commuting.general-information-list1-item2-bold")}
          </strong>
          {t("tasks.weekly-commuting.general-information-list1-item2-part2")}{" "}
        </li>
        <li>{t("tasks.weekly-commuting.general-information-list1-item3")}</li>
      </ul>
    </div>
  );
};

// TASK CERTIFICATE RESIDENCE
export const CertificateResidenceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <ul>
        <li>
          {t("tasks.weekly-commuting.certificate-residence-list1-item1-part1")}{" "}
          <a
            href="https://www.formulare-bfinv.de/ffw/form/display.do?%24context=5927A66C179B6BDB71B3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.weekly-commuting.certificate-residence-list1-item1-link")}{" "}
          </a>
          {t("tasks.weekly-commuting.certificate-residence-list1-item1-part2")}{" "}
        </li>
        <li>{t("tasks.weekly-commuting.certificate-residence-list1-item2")}</li>
      </ul>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.formulare-bfinv.de/ffw/form/display.do?%24context=5927A66C179B6BDB71B3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.weekly-commuting.certificate-residence-list1-item3")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK WORK PERMIT
export const WeeklyCertificateResidenceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.work-permit-paragraph1")}</p>

      <p>
        <strong>
          {t("tasks.weekly-commuting.work-permit-paragraph2-bold")}{" "}
        </strong>{" "}
        {t("tasks.weekly-commuting.work-permit-paragraph2")}
      </p>
      <p>
        <strong>{t("tasks.weekly-commuting.work-permit-list2-bold")} </strong>{" "}
      </p>
      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuting.work-permit-sublist2-bold")}
          </strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.weekly-commuting.work-permit-sublist2-item1")}</li>
            <li>{t("tasks.weekly-commuting.work-permit-sublist2-item2")} </li>
          </ul>
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.work-permit-sublist3-bold")}
          </strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.weekly-commuting.work-permit-sublist3-item1")}</li>
            <li>{t("tasks.weekly-commuting.work-permit-sublist3-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.work-permit-sublist4-bold")}
          </strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.weekly-commuting.work-permit-sublist4-item1")}</li>
            <li>{t("tasks.weekly-commuting.work-permit-sublist4-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.work-permit-sublist5-bold")}
          </strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.weekly-commuting.work-permit-sublist5-item1")}</li>
            <li>{t("tasks.weekly-commuting.work-permit-sublist5-item2")}</li>
          </ul>
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.work-permit-sublist6-bold")}
          </strong>
          <ul style={{ listStyleType: "circle" }}>
            <li>{t("tasks.weekly-commuting.work-permit-sublist6-item1")}</li>
            <li>{t("tasks.weekly-commuting.work-permit-sublist6-item2")}</li>
          </ul>
        </li>
      </ul>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.sem.admin.ch/sem/de/home/sem/kontakt/kantonale_behoerden/adressen_kantone_und.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.work-permit-list1-link")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK BANK ACCOUNT

export const OpenBankAccountCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.open-bank-paragraph1")}</p>
    </div>
  );
};

// TASK PHONE ACCOUNT

export const MobilePhoneCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.mobile-phone-paragraph1")}</p>
    </div>
  );
};

// TASK HEALTH INSURANCE

export const WeeklyHealthInsuranceCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.health-insurance-paragraph1")}</p>
      <p>
        <strong>
          {t("tasks.weekly-commuting.health-insurance-paragraph2-bold")}{" "}
        </strong>
        {t("tasks.weekly-commuting.health-insurance-paragraph2")}
      </p>

      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuting.health-insurance-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.health-insurance-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.health-insurance-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.health-insurance-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.health-insurance-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.health-insurance-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK INSURANCES FOR COMMUTERS

export const InsurancesCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <strong>
            {t("tasks.weekly-commuting.insurance-commuters-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.insurance-commuters-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.insurance-commuters-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.insurance-commuters-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.insurance-commuters-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.insurance-commuters-list1-item3")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.insurance-commuters-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.insurance-commuters-list1-item4")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.insurance-commuters-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.insurance-commuters-list1-item5")}
        </li>
      </ul>
    </div>
  );
};

// TASK RETIREMENT FOR COMMUTERS

export const RetirementCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.retirement-paragraph1")}</p>
      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuting.retirement-list1-item1-bold")}
          </strong>
          {t("tasks.weekly-commuting.retirement-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.retirement-list1-item2-bold")}
          </strong>
          {t("tasks.weekly-commuting.retirement-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.retirement-list1-item3-bold")}
          </strong>
          {t("tasks.weekly-commuting.retirement-list1-item3")}
        </li>
      </ul>

      <p>
        {t("tasks.weekly-commuting.retirement-paragraph2-part1")}{" "}
        <strong>
          {t("tasks.weekly-commuting.retirement-paragraph2-bold")}
        </strong>
        {t("tasks.weekly-commuting.retirement-paragraph2-part2")}
      </p>
      <p>
        <strong>
          {t("tasks.weekly-commuting.retirement-paragraph3-bold")}
        </strong>{" "}
        {t("tasks.weekly-commuting.retirement-paragraph3")}
      </p>
    </div>
  );
};

// TASK TAXATION

export const WeeklyTaxationCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.taxation-paragraph1")}</p>
    </div>
  );
};

// TASK APARTMENT SEARCH
export const WeeklyApartmentSearchDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.weekly-commuting.apartment-search-paragraph1")}</p>

      <p>
        <strong>
          {t("tasks.weekly-commuting.apartment-search-paragraph2-bold")}
        </strong>
      </p>
      <ul>
        <li>
          <a
            href="https://www.homegate.ch/de"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.weekly-commuting.apartment-search-list1-item1")}
          </a>
        </li>
        <li>
          <a
            href="https://www.homegate.ch/de"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.weekly-commuting.apartment-search-list1-item2")}
          </a>
        </li>
      </ul>

      <p>
        <strong>
          {t("tasks.weekly-commuting.apartment-search-paragraph3-bold")}
        </strong>
      </p>
      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuting.apartment-search-list2-item1-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuting.apartment-search-list2-item1-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.apartment-search-list2-item2-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuting.apartment-search-list2-item2-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.apartment-search-list2-item3-bold")}
          </strong>
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.apartment-search-list2-item4-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuting.apartment-search-list2-item4-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.apartment-search-list2-item5-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuting.apartment-search-list2-item5-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.apartment-search-list2-item6-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuting.apartment-search-list2-item6-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuting.apartment-search-list2-item7-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuting.apartment-search-list2-item7-normal")}
        </li>
      </ul>

      <p>
        <strong>
          {t("tasks.weekly-commuting.apartment-search-paragraph4-bold")}
        </strong>
        {t("tasks.weekly-commuting.apartment-search-paragraph4-normal")}
      </p>

      <p>
        <strong>
          {t("tasks.weekly-commuting.apartment-search-paragraph5-bold")}
        </strong>
        {t("tasks.weekly-commuting.apartment-search-paragraph5-normal")}
      </p>
    </div>
  );
};

// TASK PICK UP PERMIT

export const WeeklyPickUpPermitDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.pick-up-permit-paragraph1")}</p>
    </div>
  );
};

// TASK REGISTER

export const WeeklyRegisterSwissDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.register-swiss-paragraph1")}</p>
      <p>{t("tasks.weekly-commuting.register-swiss-paragraph2")}</p>
    </div>
  );
};

// TASK PICK UP PERMIT

export const WeeklyOpenBankDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.open-bank-paragraph1")}</p>
    </div>
  );
};

// TASK CURRENCY EXCHANGE

export const WeeklyCurrencyExchangeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.currency-exchange-paragraph1")}</p>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.revolut.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.currency-exchange-list1-item1")}
          </a>
        </li>
        <li>
          <a
            href="https://wise.com/ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.currency-exchange-list1-item2")}
          </a>
        </li>
        <li>
          <a
            href="https://www.neon-free.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.currency-exchange-list1-item3")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK PHONE

export const WeeklyPhoneAndInternetDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.weekly-commuting.phone-internet-paragraph1")}
        {t("tasks.weekly-commuting.phone-internet-paragraph2")}
        <a
          href={t("tasks.weekly-commuting.phone-internet-link-alao")}
          target="_blank"
          rel="noopener noreferrer"
        >alao.com</a>
        {t("tasks.weekly-commuting.phone-internet-paragraph3")}
        
      </p>
    </div>
  );
};

// SWISS INSURANCE PHONE

export const WeeklyGetSwissInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.swiss-insurance-paragraph1")}</p>
    </div>
  );
};

// TASK RECOGNITION OF PROF

export const WeeklyRecognitionProfesionalQualificationsDescription: React.FC =
  () => {
    const [t, i18n] = useTranslation("global");
    return (
      <div>
        <p>
          {t("tasks.weekly-commuting.profesional-qualifications-paragraph1")}
        </p>

        <p>
          <strong>
            {t(
              "tasks.weekly-commuting.profesional-qualifications-paragraph2-bold"
            )}
          </strong>
        </p>

        <ul>
          <li>
            <p>
              <strong>
                {" "}
                {t(
                  "tasks.weekly-commuting.profesional-qualifications-list1-item1-bold"
                )}{" "}
              </strong>
              <a
                href="https://www.bag.admin.ch/bag/de/home/berufe-im-gesundheitswesen/auslaendische-abschluesse-gesundheitsberufe.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(
                  "tasks.weekly-commuting.profesional-qualifications-list1-item1-link1"
                )}
              </a>
              ,{" "}
              <a
                href="https://www.bag.admin.ch/bag/de/home/berufe-im-gesundheitswesen/auslaendische-abschluesse-gesundheitsberufe.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(
                  "tasks.weekly-commuting.profesional-qualifications-list1-item1-link2"
                )}{" "}
              </a>
            </p>
          </li>
          <li>
            {" "}
            <strong>
              {" "}
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item2-bold"
              )}{" "}
            </strong>
            <a
              href="https://www.edk.ch/de/themen/diplomanerkennung"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item2-link"
              )}
            </a>
          </li>

          <li>
            {" "}
            <strong>
              {" "}
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item3-bold"
              )}{" "}
            </strong>
            {t(
              "tasks.weekly-commuting.profesional-qualifications-list1-item3-1"
            )}
            <a
              href="https://verwaltung.bund.de/leistungsverzeichnis/DE/leistung/99018033012000/herausgeber/HE-344488931/region/06"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item3-link"
              )}
            </a>{" "}
            {t(
              "tasks.weekly-commuting.profesional-qualifications-list1-item3-3"
            )}{" "}
          </li>
          <li>
            {" "}
            <strong>
              {" "}
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item4-bold"
              )}{" "}
            </strong>
            <a
              href="https://www.edk.ch/de/themen/diplomanerkennung"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item4-link"
              )}
            </a>
          </li>
          <li>
            {" "}
            <strong>
              {" "}
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item5-bold"
              )}{" "}
            </strong>
            <a
              href="https://www.sbfi.admin.ch/sbfi/de/home/bildung/diploma.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                "tasks.weekly-commuting.profesional-qualifications-list1-item5-link"
              )}
            </a>
          </li>
        </ul>
      </div>
    );
  };

export const WeeklyEBankingTwintDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        <ul>
          <li>
            {" "}
            <strong>
              {" "}
              {t("tasks.weekly-commuting.ebanking-twint-list1-item1-bold")}
            </strong>
            {t("tasks.weekly-commuting.ebanking-twint-list1-item1")}
          </li>

          <li>
            {" "}
            <strong>
              {" "}
              {t("tasks.weekly-commuting.ebanking-twint-list1-item2-bold")}
            </strong>
            {t("tasks.weekly-commuting.ebanking-twint-list1-item2")}
          </li>
        </ul>

        <ul>
          <a
            href="https://www.twint.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.ebanking-twint-list1-item3")}
          </a>
        </ul>
      </p>
    </div>
  );
};

export const WeeklyMobilityDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.mobility-paragraph1")}</p>

      <p>
        <strong>{t("tasks.weekly-commuting.mobility-paragraph2-bold")} </strong>{" "}
      </p>
      <ul>
        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.mobility-list1-item1-1")}
          <br />
          <a
            href="https://www.sbb.ch/de/billette-angebote/abos/halbtax.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-subitem1")}
          </a>
          <br />
          <a
            href="https://www.swisspass.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-subitem2")}
          </a>
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.mobility-list1-item2")}
          <br />
          <a
            href="https://www.swisspass.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-subitem2")}
          </a>
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.mobility-list1-item3")}
          <br />
          <a
            href="https://www.mobility.ch/de/privatkunden"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-subitem3")}
          </a>
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.mobility-list1-item4")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.mobility-list1-item5")}
          <br />
          <a
            href="https://www.publibike.ch/de/home?gclid=CjwKCAjw3dCnBhBCEiwAVvLcu77FFzg7FO912qp_m7do5XTvElZae6KMEXWU4NOArjc3fnxrFedQVRoCeDwQAvD_BwE"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {t("tasks.weekly-commuting.mobility-list1-subitem5")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export const WeeklySupermarketsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.supermarkets-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.supermarkets-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.supermarkets-list1-item1")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.supermarkets-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.supermarkets-list1-item2")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.supermarkets-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.supermarkets-list1-item3")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.supermarkets-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.supermarkets-list1-item4")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.supermarkets-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.supermarkets-list1-item5")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.supermarkets-list1-item6-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.supermarkets-list1-item6")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuting.supermarkets-list1-item7-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuting.supermarkets-list1-item7")}
        </li>
      </ul>
    </div>
  );
};

export const WeeklyOnlineShoppingDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.online-shopping-paragraph1")}</p>
      <p>
        {t("tasks.weekly-commuting.online-shopping-paragraph2-part1")}
        <a
          href="https://www.galaxus.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          galaxus.ch
        </a>{" "}
        {t("tasks.weekly-commuting.online-shopping-paragraph2-part2")}
        <a
          href="https://www.digitec.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          digitec.ch
        </a>
        {t("tasks.weekly-commuting.online-shopping-paragraph2-part3")}
        <br />
        <br />
        <strong>
          {" "}
          {t("tasks.weekly-commuting.online-shopping-paragraph3")}{" "}
        </strong>
        {t("tasks.weekly-commuting.online-shopping-paragraph4-part1")}
        {" "}
        <a
          href="https://www.tutti.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          tutti.ch
        </a>{" "}
        {t("tasks.weekly-commuting.online-shopping-paragraph4-part2")}
        {" "}
        <a
          href="https://www.ricardo.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ricardo.ch
        </a>
        {t("tasks.weekly-commuting.online-shopping-paragraph4-part3")}
      </p>
    </div>
  );
};

export const WeeklySerafeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.serafe-paragraph1")}</p>
    </div>
  );
};

export const WeeklyWasteDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.weekly-commuting.waste-paragraph1")}

        <ul>
          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuting.waste-list1-item1-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuting.waste-list1-item1")}
          </li>

          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuting.waste-list1-item2-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuting.waste-list1-item2-part1")}{" "}
            <a
              href="https://www.swisspass.ch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {t("tasks.weekly-commuting.waste-list1-item2-part2")}{" "}
            </a>
            {t("tasks.weekly-commuting.waste-list1-item2-part3")}
          </li>

          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuting.waste-list1-item3-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuting.waste-list1-item3")}
          </li>

          <li>
            <strong>
              {t("tasks.weekly-commuting.waste-list1-item4-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuting.waste-list1-item4")}
          </li>

          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuting.waste-list1-item5-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuting.waste-list1-item5")}
          </li>

          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuting.waste-list1-item6-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuting.waste-list1-item6")}
          </li>

          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuting.waste-list1-item7-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuting.waste-list1-item7")}
          </li>
        </ul>
      </p>
    </div>
  );
};

// TASK IMPORTANT DOCUMENTS READY
export const WeeklyRetirementDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.weekly-commuting.retirement-paragraph1")}</p>
      <p>
        <strong>{t("tasks.weekly-commuting.retirement-paragraph2-bold")}</strong> {" "}
      </p>

      <ul>
        <p>{t("tasks.weekly-commuting.retirement-paragraph3")}</p>

        <li>
          <strong>{t("tasks.weekly-commuting.retirement-list1-item1-bold")}</strong>{" "}
          {t("tasks.weekly-commuting.retirement-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.weekly-commuting.retirement-list1-item2-bold")}</strong>{" "}
          {t("tasks.weekly-commuting.retirement-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.weekly-commuting.retirement-list1-item3-bold")}</strong>{" "}
          {t("tasks.weekly-commuting.retirement-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK PURCHASE VIGNETTE
export const WeeklyTaxDeclarationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuting.tax-declaration-paragraph1")}</p>
    </div>
  );
};
